import React, { useEffect, useState } from "react";
import { initFacebookSDK } from "../../../layouts/LoginRegSSOIcon/SocialMediaButtons/FacebookLogin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserGroup,
  faMagnifyingGlass,
  faSuitcase,
  faCreditCard,
  faCopy,
  faCheck,
  faFile,
  faHouse,
  faUser,
  faUserTag,
  faUsers,
  faArrowLeft,
  faWandMagicSparkles,
  faCircleExclamation,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import "../../../assets/scss/layout/Sidebar/_adminSidebar.scss";
import Image from "../../../components/UI/Image";
import CustomButton from "../../../components/UI/CustomButton";
import CustomModal from "../../../components/UI/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import Config from "../../../Config/config";
import axios from "axios";
import { useAuthToken } from "../../../Utilities/Auth/Token";
import { LogoutAndClearProfile } from "../../../services/Actions/logoutAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const AdminSidebar = () => {
  // const isAIMLOn = useSelector((state) => state.reducer.aimlInfo?.isOn);
  const isAIMLOn = useSelector(
    (state) => state.reducer.feature.isfeatures.withAI
  );

  const isOnClient = useSelector((state) => state.reducer.aimlInfo?.isOnClient);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const token = localStorage.getItem("refreshToken");
  const token = useAuthToken();

  const [modalShow, setModalShow] = useState(false);
  const [url, setUrl] = useState(null);

  const location = useLocation();

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  const handleLogout = () => {
    setModalShow(true);
  };

  const handleModalClose = async () => {
    // const response = await axios.post(
    //   `${Config.admin}/api/v1/admin/auth/logout`,
    //   null,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );

    try {
      const response = await axios.post(
        `${Config.nxtApi}/api/client/logout`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const FB = window.FB;
      if (FB) {
        console.log("Logging out from Facebook...");
        FB.logout(function (response) {
          // Log out successful
          console.log("User logged out from Facebook");
        });
      }

      localStorage.clear();
      sessionStorage.clear();
      setModalShow(false);
      dispatch(LogoutAndClearProfile());
      navigate("/");
    } catch (error) {
      localStorage.clear();
      sessionStorage.clear();
      setModalShow(false);
      dispatch(LogoutAndClearProfile());
      navigate("/");
      console.log(error);
    }
  };

  const handleManageJobsClick = () => {
    navigate("/admin/ats/manage-job");
  };

  const handleTalentListClick = () => {
    navigate("/admin/ats/talent-list");
  };

  const handlePoolingtListClick = () => {
    navigate("/admin/ats/pooling-list");
  };

  const handleHiredListClick = () => {
    navigate("/admin/ats/hired-list");
  };

  const handleUserManagementClick = () => {
    navigate("/admin/ats/user");
  };

  const handleDashBoardClick = () => {
    navigate("/admin/ats/dashboard");
  };

  const handleBackToNxtProfile = () => {
    navigate("/client/company-profile");
  };

  const handleSubscriptionClick = () => {
    navigate("/admin/ats/subscription");
  };
  const handleChatBot = () => {
    navigate("/admin/ats/chatbot");
  };

  // Admin
  const handleClientListClick = () => {
    navigate("/admin/client-list");
  };

  const tooltip = (
    <Tooltip id="tooltip">
      Qualified applicants who have expressed interest in a job posting
    </Tooltip>
  );

  const tooltip1 = (
    <Tooltip id="tooltip1">
      Your top candidates who were selected and hired for open positions
    </Tooltip>
  );

  const tooltip2 = (
    <Tooltip id="tooltip2">
      Didn't land the role, but their skills make them a great fit for future
      openings
    </Tooltip>
  );
  const tooltip3 = (
    <Tooltip id="tooltip2">
      Access personalized assistance and NXT platform knowledge to enhance your
      employer experience with our AI chatbot.
    </Tooltip>
  );

  return (
    <div className="superAdminSidebar">
      <div className="sidebarLinkContainer">
        <Image
          height={54}
          width={150}
          src={require("../../../assets/images/Logo/Brand/nxtDefault.png")}
        />

        {/*   <div className="admin-sidebar__job_text mt-2">User management</div>
        <ul className="ps-0">
          <li className="p-2" onClick={handleUserManagementClick}>
            <FontAwesomeIcon icon={faUserGroup} /> Members
          </li>
        </ul> */}
        {/* <div className="admin-sidebar__job_text mt-2">Search Talent</div>
        <ul className="ps-0">
          <li className="p-2">
            <FontAwesomeIcon icon={faMagnifyingGlass} /> Search Talent
          </li>
        </ul> */}

        {/* ATS */}
        <div
          // ATS
          className="admin-sidebar__job_text admin-sidebar__back-text mt-2 mb-4"
          onClick={handleBackToNxtProfile}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Back to NXT Profile
        </div>
        <div className="admin-sidebar__job_text mt-2">ATS Dashboard</div>
        <ul className="ps-0">
          <li
            className={`p-2 ${url === "/admin/ats/dashboard" ? "act-li" : ""}`}
            onClick={handleDashBoardClick}
          >
            <FontAwesomeIcon icon={faHouse} /> Dashboard
          </li>
        </ul>
        <div className="admin-sidebar__job_text mt-2">Jobs</div>
        <ul className="ps-0">
          <li
            className={`p-2 d-flex align-items-center ${
              url === "/admin/ats/manage-job" ? "act-li" : ""
            }`}
            onClick={handleManageJobsClick}
          >
            <FontAwesomeIcon className="mx-1" icon={faSuitcase} /> Manage Jobs
            <FontAwesomeIcon icon={faWandMagicSparkles} className="ms-auto" />
          </li>
          <li
            className={`p-2 ms-4 ${
              url === "/admin/ats/talent-list" ? "act-li" : ""
            }`}
            onClick={handleTalentListClick}
          >
            <div className="d-flex align-items-center">
              <FontAwesomeIcon className="ms-1 me-2" icon={faUser} /> Applicant
              Pool{" "}
              <div className="talent-tooltip ms-auto d-block">
                <OverlayTrigger placement="right" overlay={tooltip}>
                  <div className="tooltip-circle">
                    <FontAwesomeIcon
                      className="exclamation-png"
                      icon={faInfo}
                    />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </li>
          <li
            className={`p-2 ms-4 ${
              url === "/admin/ats/pooling-list" ? "act-li" : ""
            }`}
            onClick={handlePoolingtListClick}
          >
            <div className="d-flex align-items-center">
              <FontAwesomeIcon className="ms-1 me-2" icon={faUser} /> Qualified
              Pool
              <div className="talent-tooltip ms-auto d-block">
                <OverlayTrigger placement="right" overlay={tooltip2}>
                  <div className="tooltip-circle">
                    <FontAwesomeIcon
                      className="exclamation-png"
                      icon={faInfo}
                    />{" "}
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </li>
          <li
            className={`p-2 ms-4 ${
              url === "/admin/ats/hired-list" ? "act-li" : ""
            }`}
            onClick={handleHiredListClick}
          >
            <div className="d-flex align-items-center">
              <FontAwesomeIcon className="ms-1 me-2" icon={faUser} /> Active
              Talents
              <div className="talent-tooltip ms-auto d-block">
                <OverlayTrigger placement="right" overlay={tooltip1}>
                  <div className="tooltip-circle">
                    <FontAwesomeIcon
                      className="exclamation-png"
                      icon={faInfo}
                    />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </li>
        </ul>
        <div className="admin-sidebar__job_text mt-2">Subscription</div>
        <ul className="ps-0">
          <li
            className={`p-2 ${
              url === "/admin/ats/subscription" ? "act-li" : ""
            }`}
            onClick={handleSubscriptionClick}
          >
            <FontAwesomeIcon icon={faCreditCard} /> Subscription
          </li>
        </ul>

        {isAIMLOn && isOnClient && (
          <>
            <div className="admin-sidebar__job_text">Chat Assistant</div>
            <ul className="ps-0 ">
              <li
                className={`p-2 ai-chatbot-text d-flex align-items-center ${
                  url === "/admin/ats/chatbot" ? "act-li" : ""
                }`}
                onClick={handleChatBot}
              >
                <FontAwesomeIcon icon={faWandMagicSparkles} className="me-2" />{" "}
                AI Chatbot
                <div className="talent-tooltip d-inline-flex ms-auto d-flex">
                  <OverlayTrigger placement="right" overlay={tooltip3}>
                    <div className="tooltip-circle">
                      <FontAwesomeIcon
                        className="exclamation-png"
                        icon={faInfo}
                      />
                    </div>
                  </OverlayTrigger>
                </div>
              </li>
            </ul>
          </>
        )}

        {/* ADMIN */}
        {/*
        <div className="admin-sidebar__job_text mt-2">Verification</div>
        <ul className="ps-0">
          <li className="p-2" onClick={handleClientListClick}>
            <FontAwesomeIcon icon={faUser} /> Client List
          </li>
        </ul>
        <div className="admin-sidebar__job_text mt-2">Reports</div>
        <ul className="ps-0">
          <li className="p-2">
            <FontAwesomeIcon icon={faCopy} /> Reports
          </li>
        </ul> */}
      </div>

      <div className="sidebar-text-container mt-5">
        <p>
          Our customer support team is here to help. For any queries, please
          email us at{" "}
          <a href="mailto:inquiry@nxt.com.ph"> inquiry@nxt.com.ph</a>
        </p>

        <div className="buttonContainer ">
          <CustomButton
            name="LOGOUT"
            className="violetNoRadiusNoHeight "
            onClick={() => handleLogout()}
          />
        </div>
      </div>

      <CustomModal
        buttonName="Close"
        show={modalShow}
        onHide={() => handleModalClose()}
        onClick={() => handleModalClose()}
        modalMessage="You have successfully logged out"
        modalType="Success"
      />
    </div>
  );
};

export default AdminSidebar;
