import React, { useState, useEffect, useRef } from "react";
import { Container, Button } from "reactstrap";
import { TabTitle } from "../../Utilities/FunctionTitle";
import HeaderTalent from "../../components/LayoutTalent/HeaderTalent";
import "../../assets/scss/Talent/Page/JobList.scss";
import BannerJobList from "../../components/LayoutTalent/BannerJobList";
import SearchFilter from "../../components/LayoutTalent/JobList/SearchFilter";
import SidebarJobList from "../../components/LayoutTalent/JobList/SidebarJobList";
import JobList from "../../components/LayoutTalent/JobList/JobList";
import axios from "axios";
import { List } from "react-content-loader";
import TalentAnalytics from "../../Utilities/Analytics/TalentAnalytics";
import { useAuthToken } from "../../Utilities/Auth/Token";
import Config from "../../Config/config";
import RecommendedJobs from "../../components/LayoutTalent/JobList/RecommendedJobs";
import { JobAI } from "../../hook/JobAI";
import { useSelector } from "react-redux";
import FilterJob from "../../components/UI/Filter/RecoJobFilter/FilterJob";
import { Col, Row } from "react-bootstrap";
import Footer from "../LandingPage/Components/Footer";

const JobListPageTalent = () => {
  TabTitle("NXT | Job List");
  const token = useAuthToken();

  const apiKey = process.env.REACT_APP_TALENT_API;
  const [searchValue, setSearchValue] = useState("");
  const [companyFilter, setCompanyFilter] = useState("");
  const [workTypeFilter, setWorkTypeFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [salaryFilter, setSalaryFilter] = useState({ min: 0, max: 0 });
  const [jobList, setJobList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLastData, setIsLastData] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const lastKeyRef = useRef(null);
  const jobListContainerRef = useRef(null);
  const [totalLength, setTotalLength] = useState(""); // Add totalLength state
  const [displayedJobs, setDisplayedJobs] = useState(10); // Initial number of displayed jobs

  const handleSearch = (searchValue) => {
    setJobInfo({
      ...jobInfo,
      jobTitle: searchValue,
    });
    setSearchValue(searchValue);
  };

  const loadMoreData = async () => {
    setIsLoading(true);

    try {
      const filterKeys = [{ attribute: "allFields", value: searchValue }];

      if (companyFilter) {
        filterKeys.push({ attribute: "company", value: companyFilter });
      }

      if (workTypeFilter) {
        filterKeys.push({
          attribute: "workArrangement",
          value: workTypeFilter.toLowerCase(),
        });
      }

      if (locationFilter) {
        filterKeys.push({
          attribute: "location",
          value: locationFilter,
        });
      }

      if (salaryFilter.min !== 0 || salaryFilter.max !== 0) {
        filterKeys.push({
          attribute: "salary",
          value: { min: salaryFilter.min, max: salaryFilter.max },
        });
      }

      const payload = {
        numRow: 10,
        sortKeys: "desc",
        lastKey: lastKeyRef.current,
        filterKeys: filterKeys,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${Config.nxtApi}/api/talent/job-list`,
        payload,
        config
      );

      const fetchedJobList = response.data.data;
      const newLastKey = response.data.lastKey;
      lastKeyRef.current = newLastKey;

      setJobList((prevJobList) => [...prevJobList, ...fetchedJobList]);
      setIsLastData(newLastKey === null);
      setIsButtonDisabled(newLastKey === null);
    } catch (error) {
      console.error("Error fetching job list:", error);
    }

    setIsLoading(false);
  };

  const handleClearSearch = () => {
    setSearchValue("");
  };

  useEffect(() => {
    fetchData();
  }, [
    searchValue,
    companyFilter,
    workTypeFilter,
    locationFilter,
    salaryFilter,
  ]);

  // useEffect(() => {
  //   const shouldReload = initialSearchVal === "" && searchValue === "";
  //   if (shouldReload) {
  //     window.location.reload();
  //   }
  // }, [initialSearchVal, searchValue]);

  // useEffect(() => {
  //   // if (initialSearchVal === "") {
  //   //   window.location.reload();
  //   // }
  //   window.location.reload();
  // }, [searchValue, initialSearchVal]);
  // console.log(searchValue, initialSearchVal);

  useEffect(() => {
    if (jobListContainerRef.current) {
      jobListContainerRef.current.scrollTop =
        jobListContainerRef.current.scrollHeight;
    }
  }, [jobList]);

  const { getRecommendedJobs, recommendedJobsData } = JobAI();

  const userInfo = useSelector(
    (state) => state.reducer.user?.currentUser.userId
  );

  const [jobInfo, setJobInfo] = useState({
    userId: userInfo,
    platform: "NXT",
    jobTitle: "",
    jobLevel: "",
    jobSpecialization: [""],
    company: "",
    minSalary: 0,
    maxSalary: 0,
    location: "",
    workArrangement: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setJobInfo({
      ...jobInfo,
      [name]: value,
    });
  };

  const handleSpecializationChange = (selectedOptions) => {
    setJobInfo({
      ...jobInfo,
      jobSpecialization: selectedOptions.map((option) => option.value),
    });
  };

  const handleSalaryChange = (values) => {
    const [minSalary, maxSalary] = values;
    setJobInfo({
      ...jobInfo,
      minSalary,
      maxSalary,
    });
  };

  useEffect(() => {
    getRecommendedJobs(jobInfo);
  }, [jobInfo, searchValue]);

  const handleJsFilter = (event) => {
    console.log(event);
    setJobInfo({
      ...jobInfo,
      jobSpecialization: [event],
    });
  };

  const handleCompanyFilter = (event) => {
    setCompanyFilter(event);
    console.log(event);
    setJobInfo({
      ...jobInfo,
      company: event,
    });
  };

  const handleLocationFilter = (event) => {
    setLocationFilter(event);
    console.log(event);
    setJobInfo({
      ...jobInfo,
      location: event,
    });
  };

  const handleSalaryRangeFilter = (event) => {
    setSalaryFilter({ min: event[0], max: event[1] });
    console.log(event);
    setJobInfo({
      ...jobInfo,
      minSalary: event[0],
      maxSalary: event[1],
    });
  };

  const handleJobLevelFilter = (event) => {
    console.log(event);
    setJobInfo({
      ...jobInfo,
      jobLevel: event,
    });
  };

  const handleWorkTypeFilter = (event) => {
    setWorkTypeFilter(event);
    console.log(event);
    setJobInfo({
      ...jobInfo,
      workArrangement: event,
    });
  };

  console.log(jobInfo);

  const fetchData = async () => {
    setIsLoading(true);
    setIsLastData(false);
    setIsButtonDisabled(false);
    try {
      const filterKeys = [{ attribute: "allFields", value: searchValue }];

      if (companyFilter) {
        filterKeys.push({ attribute: "company", value: companyFilter });
      }

      if (workTypeFilter) {
        filterKeys.push({
          attribute: "workArrangement",
          value: workTypeFilter.toLowerCase(),
        });
      }

      if (locationFilter) {
        filterKeys.push({
          attribute: "location",
          value: locationFilter,
        });
      }

      if (salaryFilter.min !== 0 || salaryFilter.max !== 0) {
        filterKeys.push({
          attribute: "salary",
          value: { min: salaryFilter.min, max: salaryFilter.max },
        });
      }

      const payload = {
        numRow: 10,
        sortKeys: "desc",
        filterKeys: filterKeys,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${Config.nxtApi}/api/talent/job-list`,
        payload,
        config
      );
      const fetchedJobList = response.data.data;
      const newLastKey = response.data.lastKey;
      lastKeyRef.current = newLastKey;

      setJobList(fetchedJobList);
      setTotalLength(response.data.total);
      setIsLastData(newLastKey === null);
      setIsButtonDisabled(newLastKey === null);
    } catch (error) {
      console.error("Error fetching job list:", error);
    }

    setIsLoading(false);
  };

  return (
    <div className="background-imagePost-talent-list">
      <TalentAnalytics />
      <div className="header-fixed">
        <HeaderTalent />
      </div>
      <div className="jobposting-banner">
        <BannerJobList />
      </div>
      <div className="search-container">
        <SearchFilter
          onSearch={handleSearch}
          isLoading={isLoading}
          onClear={handleClearSearch}
        />
      </div>
      <Container className="jobposting-body">
        <div
          className="talent-list-container talent__list--recommendation"
          ref={jobListContainerRef}
        >
          <div className="talent__list--recommendation--filter">
            <Row>
              <Col md="3" sm="12" xs>
                <FilterJob
                  jsFilter={handleJsFilter}
                  companyFilter={handleCompanyFilter}
                  jobLevelFilter={handleJobLevelFilter}
                  locationFilter={handleLocationFilter}
                  salaryRangeFilter={handleSalaryRangeFilter}
                  workTypeFilter={handleWorkTypeFilter}
                />
              </Col>
              <Col md="9" sm="12" xs>
                <div className="talent__list--recommendation--section">
                  {/* <RecommendedJobs joblist={recommendedJobsData} />{" "} */}
                  <JobList
                    joblist={jobList}
                    totalLength={totalLength}
                    loadMoreData={loadMoreData}
                    isLoading={isLoading}
                  />
                  <div className="joblist-load-more">
                    <Button
                      onClick={loadMoreData}
                      disabled={isButtonDisabled}
                      className="load-more-button"
                    >
                      {isLastData ? "No more to load" : "Load More"}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            {/* <div className="talent__list--recommendation--section">
              <JobList
                joblist={jobList}
                totalLength={totalLength}
                loadMoreData={loadMoreData}
                isLoading={isLoading}
              />{" "}
              <div className="joblist-load-more">
                <Button
                  onClick={loadMoreData}
                  disabled={isButtonDisabled}
                  className="load-more-button"
                >
                  {isLastData ? "No more to load" : "Load More"}
                </Button>
              </div>
            </div> */}
          </div>
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default JobListPageTalent;
