import React, { useState } from "react";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import moment from "moment";
import "../../../assets/scss/Talent/Layout/MyResume/Modal/WorkHistoryModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import GlobalText from "../../../assets/global/GlobalText.json";
import axios from "axios";
import SuccessModal from "../../SuccessModal";
import WarningModal from "../../WarningModal";
import Loader from "../../../layouts/loader/Loader";
import { useAuthToken } from "../../../Utilities/Auth/Token";
import Config from "../../../Config/config";

const WorkHistoryModal = ({ isOpen, close, reloadWorkHistory }) => {
  const token = useAuthToken();
  const apiKey = process.env.REACT_APP_TALENT_API;

  const [companyName, setcompanyName] = useState("");
  const [jobLevel, setjobLevel] = useState("");
  const [jobRole, setjobRole] = useState("");
  const [employmentType, setemploymentType] = useState("");
  const [workSetup, setworkSetup] = useState("");
  const [industry, setindustry] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [isPresentClicked, setisPresentClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleModal2 = () => {
    setModalOpen2(!modalOpen2);
  };

  const handleConfirm = () => {
    setModalOpen(!modalOpen);
    reloadWorkHistory();
  };

  const [errors, setErrors] = useState({
    companyName: "",
    jobRole: "",
    jobLevel: "",
    employmentType: "",
    workSetup: "",
    industry: "",
    startDate: "",
    endDate: "",
  });

  const handleValidation = () => {
    let newErrors = {};

    if (jobRole === "") {
      newErrors.jobRole = "Most Recent Job is required";
    }
    if (jobLevel === "") {
      newErrors.jobLevel = "Job Level is required";
    }
    if (companyName === "") {
      newErrors.companyName = "Most Recent Company is required";
    }

    if (companyName === "") {
      newErrors.companyName = "Most Recent Company is required";
    }

    if (companyName.length > 255) {
      newErrors.companyName = "Company name must be 255 characters or less";
    }

    if (jobRole.length > 255) {
      newErrors.jobRole = "Job role must be 255 characters or less";
    }

    if (employmentType === "") {
      newErrors.employmentType = "Employment Type is required";
    }
    if (workSetup === "") {
      newErrors.workSetup = "Work Setup is required";
    }
    if (industry === "") {
      newErrors.industry = "Industry is required";
    }
    if (startDate === "") {
      newErrors.startDate = "Start Date is required";
    }

    if (endDate === "") {
      newErrors.endDate = "End Date is required";
    }

    if (moment(endDate).isSameOrBefore(moment(startDate))) {
      newErrors.endDate = "End date must be greater than start date";
    }

    // Set the new errors
    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const SaveHistory = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state to true

    // Validation Function Triggering
    const isValid = await handleValidation();

    if (!isValid) {
      setIsLoading(false);
      return;
    }

    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };

      const payload = {
        companyName,
        jobLevel,
        jobRole,
        employmentType,
        workSetup,
        industry,
        experienceDate: {
          startDate,
          endDate,
        },
        isPresent: isPresentClicked ? 0 : 1,
      };

      const { data } = await axios.post(
        `${Config.nxtApi}/api/talent/workhistory`,
        payload,
        {
          headers,
        }
      );

      if (data.code === "0") {
        toggleModal();
      } else {
        toggleModal2();
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const clickPresent = () => {
    setisPresentClicked(!isPresentClicked);
    if (!isPresentClicked) {
      const today = moment().format();
      setendDate(today);
      document.getElementById("checkboxContainer").style.marginTop = "40px";
    } else {
      document.getElementById("checkboxContainer").style.marginTop = "40px";
      document.getElementById("checkboxContainer").style.marginTop = "0px";
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={close} className="modal-container">
        <ModalHeader
          toggle={() => {
            close();
          }}
          className="modal-header-container"
        >
          Work History
        </ModalHeader>
        <ModalBody className="modal-body-container">
          <FormGroup>
            <Label>
              Company Name <span className="required-field">*</span>
            </Label>
            <FormGroup className="logo-company-name">
              {/* <div className="logo-container" onClick={clickLogo}>
                <FontAwesomeIcon icon={faBuilding} className="icon" />
                <Input type="file" hidden id="upload-logo" />
              </div> */}
              <Input
                type="text"
                value={companyName}
                invalid={!!errors.companyName}
                onFocus={() => setErrors({ ...errors, companyName: "" })}
                onChange={(event) => {
                  setcompanyName(event.target.value);
                }}
              />
              {errors.companyName ? (
                <FormFeedback className="error-msg" color="danger">
                  {errors.companyName}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </FormGroup>
          <FormGroup>
            <Label>Job Level</Label>
            <Input
              type="select"
              value={jobLevel}
              invalid={!!errors.jobLevel}
              onFocus={() => setErrors({ ...errors, jobLevel: "" })}
              onChange={(event) => {
                setjobLevel(event.target.value);
              }}
            >
              {GlobalText.jobLevelOption.map((option, key) => (
                <option
                  key={key}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </option>
              ))}
            </Input>
            {errors.jobLevel ? (
              <FormFeedback className="error-msg" color="danger">
                {errors.jobLevel}
              </FormFeedback>
            ) : null}
          </FormGroup>
          <FormGroup>
            <Label>
              Role<span className="required-field">*</span>
            </Label>
            <Input
              type="text"
              value={jobRole}
              invalid={!!errors.jobRole}
              onFocus={() => setErrors({ ...errors, jobRole: "" })}
              onChange={(event) => {
                setjobRole(event.target.value);
              }}
            />
            {errors.jobRole ? (
              <FormFeedback className="error-msg" color="danger">
                {errors.jobRole}
              </FormFeedback>
            ) : null}
          </FormGroup>
          <FormGroup>
            <Label>Work Setup</Label>
            <Input
              type="select"
              value={employmentType}
              invalid={errors.employmentType}
              onFocus={() => setErrors({ ...errors, employmentType: "" })}
              onChange={(event) => {
                setemploymentType(event.target.value);
              }}
            >
              {GlobalText.employmentType.map((option, key) => (
                <option
                  key={key}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </option>
              ))}
            </Input>
            {errors.employmentType ? (
              <FormFeedback className="error-msg" color="danger">
                {errors.employmentType}
              </FormFeedback>
            ) : null}
          </FormGroup>
          <FormGroup>
            <Label>Work Arrangement</Label>
            <Input
              type="select"
              onChange={(event) => {
                setworkSetup(event.target.value);
              }}
              value={workSetup}
              invalid={!!errors.workSetup}
              onFocus={() => setErrors({ ...errors, workSetup: "" })}
            >
              {GlobalText.workSetupOption.map((option, key) => (
                <option
                  key={key}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </option>
              ))}
            </Input>
            {errors.workSetup ? (
              <FormFeedback className="error-msg" color="danger">
                {errors.workSetup}
              </FormFeedback>
            ) : null}
          </FormGroup>
          <FormGroup>
            <Label>Industry</Label>
            <Input
              type="select"
              value={industry}
              invalid={!!errors.industry}
              onFocus={() => setErrors({ ...errors, industry: "" })}
              onChange={(event) => {
                setindustry(event.target.value);
              }}
            >
              <option disabled value="">
                Select an Industry
              </option>
              {GlobalText.industryOptions.map((option, key) => (
                <option
                  key={key}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </option>
              ))}
            </Input>
            {errors.industry ? (
              <FormFeedback className="error-msg" color="danger">
                {errors.industry}
              </FormFeedback>
            ) : null}
          </FormGroup>
          <div>
            <div className="start-end-date">
              <FormGroup>
                <Label>
                  Start Date<span className="required-field">*</span>
                </Label>
                <Input
                  type="date"
                  value={startDate}
                  invalid={!!errors.startDate}
                  onFocus={() => setErrors({ ...errors, startDate: "" })}
                  onChange={(event) => {
                    setstartDate(event.target.value);
                  }}
                ></Input>
                {errors.startDate ? (
                  <FormFeedback
                    className="error-msg"
                    color="danger"
                    // style={{ display: "block" }}
                  >
                    {errors.startDate}
                  </FormFeedback>
                ) : null}
              </FormGroup>
              <FormGroup>
                <div className="date-checkbox">
                  {isPresentClicked ? (
                    <FormGroup></FormGroup>
                  ) : (
                    <>
                      <Label>
                        End Date<span className="required-field">*</span>
                      </Label>
                      <Input
                        type="date"
                        value={endDate}
                        onChange={(event) => {
                          setendDate(event.target.value);
                        }}
                        // disabled={isPresentClicked}
                      ></Input>
                    </>
                  )}
                  {errors.endDate && (
                    <p
                      className="error-msg"
                      color="danger"
                      // style={{ display: "block" }}
                    >
                      {errors.endDate}
                    </p>
                  )}

                  <div className="checkbox" id="checkboxContainer">
                    <Input type="checkbox" onClick={clickPresent} />{" "}
                    <Label>Present</Label>
                  </div>
                </div>
              </FormGroup>
            </div>
            <FormGroup className="start-end-msg"></FormGroup>
          </div>
        </ModalBody>
        <ModalFooter className="modal-footer-container">
          <Button
            className="cancel"
            onClick={() => {
              close();
            }}
          >
            Cancel
          </Button>
          <Button className="save" onClick={SaveHistory}>
            {isLoading ? <Loader /> : "Save"}
          </Button>
        </ModalFooter>
      </Modal>
      <SuccessModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        iconImage={require("../../../assets/images/SuccessIcon.png")}
        modalLabel="Success"
        modalSublabel="Successfully added work history."
        confirmButtonText="Ok"
        onConfirm={handleConfirm}
      />
      <WarningModal
        modalOpen={modalOpen2}
        toggleModal={toggleModal2}
        iconImage={require("../../../assets/images/WarningIcon.png")}
        modalLabel="Warning"
        modalSublabel="Internal Server Error"
        confirmButtonText="Confirm"
        onConfirm={toggleModal2}
      />
    </div>
  );
};

export default WorkHistoryModal;
