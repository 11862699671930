import React, { useEffect, useState } from "react";
import AdminSidebar from "../../../../layouts/Sidebar/Admin/AdminSidebar";
import Navbar from "../../../../layouts/Navbar";
import WhiteContainer from "../../../../layouts/Container/WhiteContainer";
import CreateJobForm from "./CreateJobForm/CreateJobForm";
import CustomSelect from "../../../../components/Form/CustomSelect";
import workArrangement from "../../../../../src/Data/company/workArrangement.json";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import CustomCreatableSelect from "../../../../components/Form/CustomCreatableSelect";
import Config from "../../../../Config/config";
import axios from "axios";
import { useAuthToken } from "../../../../Utilities/Auth/Token";
import { useLocation, useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { CreatableSingle } from "../../../../components/UI/CreatableSelect/CreatableSingle";
import { useClient } from "../../../../hook/useClient";
import { useAI } from "../../../../hook/useAI";
import CustomModal from "../../../../components/UI/Modal";
import { Message } from "../../../../Utilities/Reusable/Message/Message";

const AttendanceTrackingAiQuestionare = () => {
  const {
    editJobPublishDraft,
    responseJobId,
    isLoadingCreateJobPublishDraft,
    getATSJobList,
    atsJobList,
    editJobStatus,
    deleteJob,
  } = useClient();
  const { getScreeningId, exposeQuestionnare, getQuestion } = useAI();
  const navigate = useNavigate();
  const location = useLocation();
  const token = useAuthToken();
  const jobId = location.state?.jobId;
  const [value, setValue] = useState(null);
  const [questionareOne, setQuestionareOne] = useState();
  const [questionareTwo, setQuestionareTwo] = useState();
  const [questionareThree, setQuestionareThree] = useState();
  const [questionareFour, setQuestionareFour] = useState();
  const [questionareFive, setQuestionareFive] = useState();
  const [exposeQuestionare, setExposeQuestionare] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [optionsForList, setOptionForList] = useState([]);
  const methods = useForm();
  const isViewJob = location.state?.editVal?.isViewJob;
  const payload = location.state?.payload;
  const allPayload = location.state?.allPayload;
  const [jobDetailsPayload, setJobDetailsPayload] = useState(payload);
  const editJob = location.state?.editJob;
  const [modalInfo, setModalInfo] = useState({ show: false });
  const [modalMode, setModalMode] = useState("");
  const [modalShow, setModalShow] = useState(false);
  console.log(payload, allPayload);
  // const getQuestion = async () => {};

  useEffect(() => {
    if (editJob) {
      getScreeningId(jobId);
    }
  }, [editJob, jobId]);

  const shouldDisable = payload?.setOptions?.jobPostStatus === "open";

  const onSubmit = async (data, buttonType) => {
    console.log(data, buttonType, forEditData);
    if (editJob && shouldDisable) {
      changeJobStatus(buttonType);
    } else if (editJob && !shouldDisable) {
      const editedQuestions = [];
      // const runEditQuestionnaire = await getQuestion(); //exposeQuestionnare= oldQuestionId, updatedAt, question
      const oldQuestions = exposeQuestionnare.map((val) => ({
        questionId: val.questionId,
        updatedAt: val.updatedAt,
        question: val.question,
      }));

      Object.keys(data).forEach((key, index) => {
        const question = data[key];
        if (question.label !== oldQuestions[index]?.question) {
          editedQuestions.push({
            oldQuestionId: oldQuestions[index]?.questionId,
            updatedAt: oldQuestions[index]?.updatedAt,
            question: question.label,
          });
        }
      });

      try {
        const runEditQuestionnaire = await getQuestion(editedQuestions, jobId);
        changeJobStatus(buttonType);
      } catch (err) {
        console.log(err);
      }
    } else {
      const setListQuestion = Object.values(data).map((items) => items.label);
      console.log(setListQuestion);

      const payload = {
        questions: setListQuestion,
        jobId,
      };
      console.log("payload", payload, data);
      try {
        const response = await axios.post(
          `${Config.ats}/api/ats/ai/screening`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response) {
          changeJobStatus(buttonType);
        }
        // if (buttonType === "publish") {
        //   setModalInfo({
        //     show: true,
        //     type: "Success",
        //     message: Message.client.aiQuestionare[200],
        //     func: "successCreatedJob",
        //     bName: "Back to manage job",
        //   });
        // } else {
        //   setModalInfo({
        //     show: true,
        //     type: "Warning",
        //     message: Message.client.aiQuestionareDraft[200],
        //     func: "draftJob",
        //     bName: "Back to manage job",
        //   });
        // }
      } catch (error) {
        console.log(error);
      }
    }
    if (buttonType === "publish") {
      setModalInfo({
        show: true,
        type: "Success",
        message: Message.client.aiQuestionare[200],
        func: "successCreatedJob",
        bName: "Back to manage job",
      });
    } else {
      setModalInfo({
        show: true,
        type: "Success",
        message: Message.client.aiQuestionareDraft[200],
        func: "draftJob",
        bName: "Back to manage job",
      });
    }
    // console.log(data);
  };

  // const onSubmit = async (data, buttonType) => {
  //   console.log(data, buttonType, forEditData);
  //   if (editJob && shouldDisable) {
  //     changeJobStatus(buttonType);
  //   } else if (editJob && !shouldDisable) {
  //     const editedQuestions = [];
  //     const oldQuestions = (exposeQuestionare || []).map((val) => ({
  //       questionId: val.questionId,
  //       updatedAt: val.updatedAt,
  //       question: val.question,
  //     }));

  //     Object.keys(data).forEach((key, index) => {
  //       const question = data[key];
  //       // Add a check for null or undefined before accessing properties
  //       if (question && question.label !== oldQuestions[index]?.question) {
  //         editedQuestions.push({
  //           oldQuestionId: oldQuestions[index]?.questionId,
  //           updatedAt: oldQuestions[index]?.updatedAt,
  //           question: question.label,
  //         });
  //       }
  //     });

  //     try {
  //       const runEditQuestionnaire = await getQuestion(editedQuestions, jobId);
  //       changeJobStatus(buttonType);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   } else {
  //     // Handle empty or null data scenario
  //     if (
  //       Object.values(data).every((item) => item !== null && item !== undefined)
  //     ) {
  //       const setListQuestion = Object.values(data).map((items) => items.label);
  //       console.log(setListQuestion);

  //       const payload = {
  //         questions: setListQuestion,
  //         jobId,
  //       };

  //       try {
  //         const response = await axios.post(
  //           `${Config.ats}/api/ats/ai/screening`,
  //           payload,
  //           {
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //             },
  //           }
  //         );
  //         if (response) {
  //           changeJobStatus(buttonType);
  //         }
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     } else {
  //       console.log("Data contains null or undefined values");
  //     }
  //   }

  //   // Show modal based on buttonType
  //   if (buttonType === "publish") {
  //     setModalInfo({
  //       show: true,
  //       type: "Success",
  //       message: Message.client.aiQuestionare[200],
  //       func: "successCreatedJob",
  //       bName: "Back to manage job",
  //     });
  //   } else {
  //     setModalInfo({
  //       show: true,
  //       type: "Success",
  //       message: Message.client.aiQuestionareDraft[200],
  //       func: "draftJob",
  //       bName: "Back to manage job",
  //     });
  //   }
  // };

  const handleOnClick = () => {
    if (
      modalInfo.func === "successCreatedJob" ||
      modalInfo.func === "draftJob" ||
      modalInfo.func === "deleteJob"
    ) {
      navigate("/admin/ats/manage-job");
    }
  };

  const validationPattern = /^[a-zA-Z0-9-_./&\s]*$/;

  console.log(jobId);
  const forEditData = location.state?.editVal;

  const handleClickCancel = () => {
    navigate("/admin/ats/manage-job");
  };

  const changeJobStatus = async (buttonType) => {
    if (editJob && shouldDisable) {
      const jobFound = atsJobList.filter((val) => val.jobId === jobId);
      const { updatedAt } = jobFound?.[0];
      console.log(updatedAt);
      delete payload.setOptions.isActive;
      payload.setOptions.jobPostStatus =
        buttonType === "draft" ? "draft" : "open";
      const response = await editJobPublishDraft(
        jobId,
        payload.setOptions,
        updatedAt
      );
    } else if (editJob && !shouldDisable) {
      console.log(payload);
      const jobFound = atsJobList.filter((val) => val.jobId === jobId);
      const { updatedAt } = jobFound?.[0];
      console.log(updatedAt);
      delete payload.setOptions.isActive;
      payload.setOptions.jobPostStatus =
        buttonType === "draft" ? "draft" : "open";

      const response = await editJobPublishDraft(
        jobId,
        payload.setOptions,
        updatedAt
      );
    } else {
      const jobFound = atsJobList.filter((val) => val.jobId === jobId);
      console.log(jobFound);

      if (jobFound.length > 0) {
        const {
          jobId,
          createdAt,
          isDeleted,
          jobTitleLowCase,
          clientId,
          jobUUIDLowCase,
          jobUUID,
          isActive,
          updatedAt,
          cityLowCase,
          ...rest
        } = jobFound[0];
        const updatedJob = {
          ...rest,
          jobPostStatus: buttonType === "publish" ? "open" : "draft",
        };

        const updatedPayload = atsJobList.map((job) =>
          job.jobId === jobId ? updatedJob : job
        );

        setJobDetailsPayload(updatedPayload);

        const response = await editJobPublishDraft(
          jobId,
          {
            ...updatedJob,
          },
          updatedAt
        );

        console.log(response);
        if (response) {
          // add show modal
          console.log("job saved");
        }
      } else {
        console.log("Job not found");
      }
    }
  };

  // const filterJobId = () => {
  //   const jobFound = atsJobList.map((val) => val.jobId === jobId);
  //   console.log(jobFound);
  // };

  useEffect(() => {
    getATSJobList();
  }, []);

  // eto yung func sa onClick sa modal boss
  const navManageJob = () => {
    navigate("/admin/manage-job");
  };

  useEffect(() => {
    if (editJobStatus === "success") {
      // setModalInfo({
      //   show: true,
      //   type: "Warning",
      //   message: Message.client.aiQuestionareDraft[200],
      //   func: "draftJob",
      //   bName: "Back to manage job",
      // });
    }
  }, [editJobStatus]);
  console.log(editJobStatus);

  useEffect(() => {
    const getQuestionListSuggestion = async () => {
      try {
        const response = await axios.get(
          `${Config.ats}/api/ats/ai/default-questions`
        );
        setOptionForList(response);
      } catch (error) {
        console.log(error);
      }
    };
    getQuestionListSuggestion();
  }, []);

  console.log(optionsForList.data?.result?.data?.questions);

  const handleChange = (newValue) => {
    setSelectedOption(newValue);
  };

  const handleCreate = (inputValue) => {
    const newOption = { label: inputValue, value: inputValue };
    setOptionForList((prev) => [...prev, newOption]);
    setSelectedOption(newOption);
  };

  const categories = Object.keys(
    optionsForList?.data?.result?.data?.questions || {}
  );

  console.log(categories);

  // const catBehavior =
  //   optionsForList.data?.result?.data?.questions?.behavior.map((item) => item);

  // const allQuestions = optionsForList.data?.result?.data?.questions
  // ? Object.entries(optionsForList.data.result.data.questions).flatMap(
  //     ([category, questions]) =>
  //       questions.map((questionText, optionIndex) => ({
  //         label: questionText,
  //         value: `${category}-${optionIndex}`,
  //       }))
  //   )
  // : [];

  const behaviorQuestions =
    optionsForList.data?.result?.data?.questions.behavior.map(
      (question, index) => ({
        label: question,
        value: `behavior-${index}`,
      })
    );

  const allQuestions = optionsForList.data?.result?.data?.questions
    ? Object.entries(optionsForList.data.result.data.questions).flatMap(
        ([category, questions]) =>
          questions.map((questionText, optionIndex) => ({
            label: `${questionText}`,
            value: `${category}-${optionIndex}`,
          }))
      )
    : [];

  console.log(allQuestions);

  const colourOptions = [{}];

  const groupOption = [
    {
      label: "Behavior",
      option: behaviorQuestions,
    },
  ];

  console.log(groupOption);

  // Pang delete ng job
  const handleDelete = async () => {
    const deleteJobPayload = {
      patchOptions: {
        jobId: payload.patchOptions.jobId,
        updatedAt: payload.patchOptions.updatedAt,
      },
      setOptions: {
        jobPostStatus: "closed",
      },
      props: [allPayload.createdAt],
    };

    try {
      await deleteJob(deleteJobPayload);
      setModalMode("delete");
      setModalInfo({
        show: true,
        type: "Warning ",
        message: Message.client.jobDeleted[200],
        func: "deleteJob",
        bName: "Yes",
        secondBName: "No",
        onHide: false,
      });
    } catch (error) {
      console.log(error);
    }
    // modal success deleted

    //
  };

  return (
    <div className="admin">
      <div className="sidebar">
        <AdminSidebar />
      </div>
      <div className="navbarContent">
        <Navbar layout="admin" />
        <div className="pageContainer create--job">
          <WhiteContainer>
            <h3
              className="create-job__main_title border-bottom pb-3 mb-4"
              id="topCreateForm"
            >
              {editJob ? "Edit Job" : "Create Job"}
            </h3>
            <div className="create-job__form pt-2 px-5 pb-5">
              <h4 className="create-job__title border-bottom pb-3 mb-4">
                Initial Screening Questions
              </h4>
              <div className="mb-4">
                Choose the questions you’d like your potential talents to answer
                during pre-screening. You can even come up with your own custom
                questions.
              </div>
              <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                  {Array(5)
                    .fill(null)
                    .map((_, index) => {
                      const question = exposeQuestionnare?.[index];
                      const category = categories[index % categories.length];
                      const options =
                        optionsForList.data?.result?.data?.questions[
                          category
                        ].map((questionText, optionIndex) => ({
                          label: questionText,
                          value: `${category}-${optionIndex}`, // Adjust value as needed
                        }));
                      return (
                        <CreatableSingle
                          key={question?.questionId || index}
                          name={`questionnaire${index + 1}`}
                          label={`Questionnaire ${index + 1}`}
                          defaultValue={
                            question
                              ? {
                                  label: question.question,
                                  value: question.question,
                                }
                              : null
                          }
                          isDisabled={shouldDisable}
                          options={allQuestions}
                        />
                      );
                    })}
                  <div className="d-flex">
                    <div className="ms-auto">
                      {/* <Button className="me-2 create-job__btn_yellow"
                      onClick={() =>
                        methods.handleSubmit((data) =>
                          submitData(data, "saveAsDraft")
                        )()
                      }>
                        Save as draft
                      </Button> */}

                      <Button
                        variant="outline-secondary"
                        className="me-2"
                        onClick={handleClickCancel}
                      >
                        <i class="fa-regular fa-circle-xmark"></i> Cancel
                      </Button>
                      {payload?.setOptions?.jobPostStatus === "draft" && (
                        <Button
                          variant="outline-secondary"
                          className="me-2 create-job__btn_yellow"
                          onClick={() =>
                            methods.handleSubmit((data) =>
                              onSubmit(data, "draft")
                            )()
                          }
                        >
                          <i class="fa-solid fa-file"></i> Save as Draft
                        </Button>
                      )}

                      {(payload.setOptions?.jobPostStatus === "open" ||
                        payload.setOptions?.jobPostStatus === "expired") && (
                        <Button
                          variant="outline-secondary"
                          className="me-2 create-job__btn_red"
                          onClick={() => handleDelete()}
                          // disabled={
                          //   isLoadingDraft ||
                          //   isLoadingPublish ||
                          //   loadSaveAsDraftJobBtn ||
                          //   loadCloseBtn
                          // }
                        >
                          <i class="fa-solid fa-file"></i> Close
                        </Button>
                      )}

                      <Button
                        className="outline-secondary create-job__btn_blue"
                        onClick={() =>
                          methods.handleSubmit((data) =>
                            onSubmit(data, "publish")
                          )()
                        }
                      >
                        Publish
                      </Button>
                    </div>
                  </div>
                </Form>
              </FormProvider>
            </div>
          </WhiteContainer>
        </div>
      </div>
      <CustomModal
        show={modalInfo.show}
        modalType={modalInfo.type}
        modalMessage={modalInfo.message}
        buttonName={modalInfo.bName}
        hasOption={true}
        onClick={() => {
          modalMode === "deleteJob" ? handleDelete() : handleOnClick();
        }}
        onHide={() => setModalInfo({ ...modalInfo, show: false })}
      />
    </div>
  );
};

export default AttendanceTrackingAiQuestionare;
