import React, { useEffect, useState } from "react";
import DragAndDrop from "../../../../../components/UI/DragAndDrop/DragAndDrop";
import { v4 as uuidv4 } from "uuid";
import fbLogo from "../../../../../assets/images/Icon/iconBuilding.png";
import WhiteContainer from "../../../../../layouts/Container/WhiteContainer";
import ImageTextContainer from "../../../../../layouts/Container/ImageTextContainer";
import "../../../../../assets/scss/Page/Admin/ATS/CandidateList/_sourcingSelection.scss";
import Note from "../Note/Note";
import axios from "axios";
import Config from "../../../../../Config/config";
import { result } from "lodash";
import CustomAtsTable from "../../../../../components/UI/Table/ATS/CustomAtsTable";
import {
  failedCandidatesTC,
  hiredCandidatesTC,
  recommendationsTC,
} from "../../../../../Utilities/Reusable/TableHead/AtsTHead";
import { useAuthToken } from "../../../../../Utilities/Auth/Token";
import { localDevBase } from "../../../../../services/API/localDevBase";
import CustomModal from "../../../../../components/UI/Modal";
import { LogoutAndClearProfile } from "../../../../../services/Actions/logoutAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  faCircleUser,
  faEye,
  faFile,
  faInfo,
  faUserPlus,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ProfilePicture from "../../../../../assets/images/SourcingSelection/ProfilePic.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { handleAxiosError } from "../../../../../Utilities/Reusable/ErrorHandler/AxiosErrorHandler";
import { Button, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import {
  faFileAlt,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-regular-svg-icons";
import AllInformation from "../../AllInformation/AllInformation";
import CustomOffcanvas from "../../../../../components/UI/Offcanvas/CustomOffcanvas";
import { useSelector } from "react-redux";
import Loader from "../../../../../layouts/loader/Loader";

const SourcingSelection = ({ item, statistics, stats, clientData }) => {
  // const [notesData, setNotesData] = useState([]);
  // const [keySkill, setKeySkills] = useState([]);
  // const [workHistory, setWorkHistory] = useState([]);
  // const [certificate, setCertificate] = useState([]);
  // const [statusHistory, setStatusHistory] = useState([]);
  // const [client, setClient] = useState({});
  console.log(item, clientData);
  const isOnClient = useSelector((state) => state.reducer.aimlInfo?.isOnClient);
  const isAIMLFeature = useSelector(
    (state) => state.reducer.feature?.isfeatures?.withAI
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [allError, setAllError] = useState("");
  const [showData, setShowData] = useState(null);
  const [showDataTalent, setShowDataTalent] = useState(null);
  const [showDataProfile, setShowDataProfile] = useState(null);
  const [isOCShowing, setisOCShowing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // temporary disable name
  const [talentSubs, setTalentSubs] = useState(true);

  const [itemData, setItemData] = useState(null);
  const handleAllError = (error) => {
    setAllError(error);
  };
  const [show, setShow] = useState(false);
  const [showInvite, setShowInvite] = useState(false);

  const handleShowInvite = (data) => {
    setShowDataTalent(data);
    setShowInvite(true);
  };

  const handleShowAiScore = (dataInvite) => {
    setShowData(dataInvite);
    setShow(true);
  };

  const handleViewProfile = (data) => {
    console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE");
    setShowDataProfile(data);
    setTalentSubs(true);
    setisOCShowing(true);
  };

  const handleClose = () => setShow(false);
  const handleCloseInvite = () => setShowInvite(false);
  const handleCloseProfile = () => setisOCShowing(false);

  const devBase = localDevBase();
  const token = useAuthToken();
  const [userData, setUserData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("SourcingAndJobSelection");
  // const [columnsFromBackendSS, setColumnsFromBackendSS] = useState(null);
  const [columnsFromBackendSS, setColumnsFromBackendSS] = useState(null);

  const handleTabClick = async (tabName) => {
    setSelectedTab(tabName);
    await getCandidateList();
  };

  useEffect(() => {
    getCandidateList();
  }, []);

  useEffect(() => {
    matchingScore();
  }, []);

  const submitInvitation = async () => {
    try {
      const payload = null;

      console.log(payload);

      // uncomment if payload is okay
      const response = await axios.post(
        `${Config.ats}/api/ats/ai/invite`,
        payload,
        {
          params: {
            applicantId: showDataTalent.applicantId,
            jobId: clientData.jobId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const sentInvitationTalent = () => {
    submitInvitation();
    setModalType("successNote");
    setShowModal(true);
  };

  const matchingScore = async () => {
    const payload = {
      createdAt: "2024-04-25T05:47:04.274Z",
      content: {
        jobId: clientData.jobId,
        platform: "NXT",
        matchCount: 3,
      },
    };

    try {
      const responseMatchingScore = await axios.post(
        `${Config.ai}/api/Matching/talent`,
        payload,
        { withCredentials: true }
      );
      setItemData(responseMatchingScore.data.content);
      console.log("get one php data", responseMatchingScore.data.content);
    } catch (error) {
      console.log("error matching score", error);
    }
  };

  // useEffect(() => {
  //   getData();
  // }, []);

  // const getData = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${devBase}/api/ats/candidate-list/${item.talentId}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //         params: {
  //           jobId: item.jobId,
  //         },
  //       }
  //     );
  //     console.log("talent id", item.talentId);
  //     setKeySkills(response.data.result.skillSet);
  //     setWorkHistory(response.data.result.workHistory);
  //     setCertificate(response.data.result.certificate);
  //     // setNotesData(response.data.result);
  //     setClient(response.data.result.client);
  //     setStatusHistory(response.data.result.statusHistory[0]?.previousStatus);
  //   } catch (error) {
  //     console.log(error);
  //     handleAxiosError(error);
  //   }
  // };

  const getCandidateList = async () => {
    console.log(clientData);
    try {
      const response = await axios.get(`${devBase}/api/ats/candidate-list`, {
        headers: {
          // "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
        params: {
          jobId: clientData.jobId,
        },
        // withCredentials: true,
      });

      const userDataWithId = response.data.result?.data.map((item) => ({
        ...item,
        // to be updated
        hiringManagerEmail: clientData.hiringManager.email,
        id: uuidv4(),
      }));

      setUserData(userDataWithId);
      generateColumns(userDataWithId);
    } catch (error) {
      setModalShow(true);
      if (error.response.status === 401) {
        setModalShow(true);
        setAllError("Error");
      }
      console.error("Error fetching candidate list:", error);
    }
  };
  const generateColumns = (userData) => {
    const hired = filterUserDataByStatus(userData, "Hired");
    const screening = filterUserDataByStatus(userData, "Screening");
    const exams = filterUserDataByStatus(userData, "Exams");
    const interview = filterUserDataByStatus(userData, "Interview");
    const initial = filterUserDataByStatus(userData, "Initial Interview");
    const final = filterUserDataByStatus(userData, "Final Interview");
    const offerLetter = filterUserDataByStatus(userData, "Offer Letter");
    const bi = filterUserDataByStatus(userData, "BI");
    const peme = filterUserDataByStatus(userData, "PEME");
    const employmentContract = filterUserDataByStatus(
      userData,
      "Employment Contract"
    );
    const preEmployment = filterUserDataByStatus(
      userData,
      "Pre Employment Reqs"
    );
    const newApplicant = filterUserDataByStatus(userData, "New Applicant");
    const failed = filterUserDataByStatus(userData, "Failed");
    const withdraw = filterUserDataByStatus(userData, "Withdraw");

    const columns = {
      [uuidv4()]: {
        name: "New Applicant",
        count: newApplicant.length,
        items: newApplicant,
      },
      [uuidv4()]: {
        name: "Screening",
        count: screening.length,
        items: screening,
      },
      [uuidv4()]: {
        name: "Exams",
        count: exams.length,
        items: exams,
      },
      [uuidv4()]: {
        name: "Initial Interview",
        count: initial.length,
        items: initial,
      },
      [uuidv4()]: {
        name: "Final Interview",
        count: final.length,
        items: final,
      },
      [uuidv4()]: {
        name: "Offer Letter",
        count: offerLetter.length,
        items: offerLetter,
      },
      [uuidv4()]: {
        name: "Background Investigation",
        count: bi.length,
        items: bi,
      },
      [uuidv4()]: {
        name: "Medical Examination",
        count: peme.length,
        items: peme,
      },
      [uuidv4()]: {
        name: "Pre Employment Requirements",
        count: preEmployment.length,
        items: preEmployment,
      },
      [uuidv4()]: {
        name: "Employment Contract",
        count: employmentContract.length,
        items: employmentContract,
      },

      [uuidv4()]: {
        name: "Hired",
        count: hired.length,
        items: hired,

        // hide if hired
        // items: [],
      },
    };

    statistics({
      inProgressLength:
        userData.length - newApplicant.length - hired.length - failed.length,
      hiredLength: hired.length,
      failedLength: failed.length,
    });

    setColumnsFromBackendSS(columns);
  };

  const filterUserDataByStatus = (userData, status) => {
    return userData.filter((user) => {
      if (user.draftStatus !== "") {
        return user.draftStatus === status;
      } else {
        return user.status === status;
      }
    });
  };

  let hired = [];
  let failed = [];
  let recommend = [];

  if (userData.length > 0) {
    hired = filterUserDataByStatus(userData, "Hired");
    failed = filterUserDataByStatus(userData, "Failed");
    recommend = filterUserDataByStatus(userData, "Recommendations");
  }

  const handleLogout = () => {
    setModalShow(false);
    setAllError("");
    dispatch(LogoutAndClearProfile());
    localStorage.clear();
    sessionStorage.clear();
    console.log("Logout ...");
    navigate("/");
  };

  const toolTipRecommendation = (
    <Tooltip id="tooltip">
      Get a curated list of top candidates perfectly matched to your job posts
      using AI technology.
    </Tooltip>
  );

  const [insights, setInsights] = useState([]);
  const [loadingStates, setLoadingStates] = useState([]);

  const getSaveInsight = async (item, index) => {
    setLoadingStates((prev) => {
      const newLoadingStates = [...prev];
      newLoadingStates[index] = true;
      return newLoadingStates;
    });

    const payload = {
      CreatedAt: "2024-04-25T05:47:04.274Z",
      Content: {
        ApplicantId: item.applicantId,
        JobId: clientData.jobId,
        Platform: "ats",
      },
    };

    try {
      const response = await axios.post(
        `${Config.ai}/api/matching/score`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInsights((prevInsights) => {
        const newInsights = [...prevInsights];
        newInsights[index] = response.data;
        return newInsights;
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingStates((prev) => {
        const newLoadingStates = [...prev];
        newLoadingStates[index] = false;
        return newLoadingStates;
      });
    }
  };

  useEffect(() => {
    if (Array.isArray(itemData)) {
      setInsights([]); // Clear previous insights
      setLoadingStates(itemData.slice(0, 3).map(() => false)); // Initialize loading states
      itemData.slice(0, 3).forEach((item, index) => {
        getSaveInsight(item, index);
      });
    }
  }, [clientData, itemData, token]);

  const retryFetch = (item, index) => {
    getSaveInsight(item, index);
  };

  return (
    <>
      <WhiteContainer>
        <div className="applicant-tabs__title mb-3">
          <div
            className={`applicant-tabs__per_title ${
              selectedTab === "SourcingAndJobSelection" ? "atsActive" : ""
            }`}
            onClick={() => handleTabClick("SourcingAndJobSelection")}
          >
            Sourcing and Job Offer
          </div>

          <div
            className={`applicant-tabs__per_title ${
              selectedTab === "Hired" ? "atsActive" : ""
            }`}
            onClick={() => handleTabClick("Hired")}
          >
            Hired
          </div>

          <div
            className={`applicant-tabs__per_title ${
              selectedTab === "FailedWithdraw" ? "atsActive" : ""
            }`}
            onClick={() => handleTabClick("FailedWithdraw")}
          >
            Failed/Withdraw
          </div>
          {isAIMLFeature && isOnClient && (
            <div
              className={`applicant-tabs__per_title d-flex align-items-center ${
                selectedTab === "Recommendations" ? "atsActive" : ""
              }`}
              onClick={() => handleTabClick("Recommendations")}
            >
              <FontAwesomeIcon className="me-2" icon={faWandMagicSparkles} />
              AI Talent Recommendations
              <OverlayTrigger placement="right" overlay={toolTipRecommendation}>
                <div className="ms-2 font-circle">
                  <FontAwesomeIcon
                    className="exclamation-png position-absolute top-50 start-50 translate-middle"
                    icon={faInfo}
                  />
                </div>
              </OverlayTrigger>
            </div>
          )}
        </div>
        {selectedTab === "Notes" && (
          <>
            <Note />
            <ImageTextContainer hideView="false" />
          </>
        )}

        {selectedTab === "Hired" && (
          <CustomAtsTable data={hired} columns={hiredCandidatesTC} />
        )}

        {selectedTab === "FailedWithdraw" && (
          <CustomAtsTable data={failed} columns={failedCandidatesTC} />
        )}

        {selectedTab === "SourcingAndJobSelection" && (
          <DragAndDrop
            columnsFromBackend={columnsFromBackendSS || {}}
            stats={stats}
            statistics={statistics}
            clientData={clientData}
            AllError={handleAllError}
          />
        )}
        {isAIMLFeature && isOnClient && (
          <>
            {selectedTab === "Recommendations" && (
              <>
                {/* <div className="select-container mt-4 mb-5 ms-3 ">
                  <div className="row gap-2">
                    <div className="col-md-2 px-0">
                      <label for="experience">Experience</label>
                      <select
                        class="form-select "
                        aria-label="Default select example"
                      >
                        <option selected>Select</option>
                      </select>
                    </div>

                    <div className="col-md-2 px-0">
                      <label for="experience">Location</label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Select</option>
                      </select>
                    </div>

                    <div className="col-md-2 px-0">
                      <label for="experience">Job Level</label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Select</option>
                      </select>
                    </div>
                  </div>
                </div> */}
                {/* <div className="button-container mb-1">
                  <button
                    // type="button"
                    className="btn btn-primary ai-scores"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                </div> */}

                <Modal
                  show={show}
                  onHide={handleClose}
                  size="lg"
                  centered
                  backdrop="static"
                  aria-labelledby="ai-match-score"
                >
                  <Modal.Header
                    closeButton
                    className="border-bottom-0 pb-0 bg-white"
                  ></Modal.Header>
                  <Modal.Body className="ai-scores__modal pt-0 bg-white pt-3">
                    <div className="profile-pic__container d-flex">
                      <img
                        className="profile-picture"
                        src={ProfilePicture}
                        alt="Profile Picture"
                      />

                      <div className="ms-3 information__container">
                        <h3 className="text-capitalize">
                          {/* {showData?.firstName} {showData?.lastName} */}
                          {showData?.ifSubs === 1
                            ? `${showData.firstName} ${showData.lastName}`
                            : "Talent"}
                        </h3>
                        <p className="text-capitalize">
                          {showData?.content?.score}
                        </p>
                      </div>
                      {/* <div className="regenarate-button__container ms-auto d-block">
                              <button className="btn btn-primary regenarate-button">
                                Regenerate
                              </button>
                            </div> */}
                    </div>
                    <div className="hr__container">
                      <hr />
                    </div>
                    <div className="ai-scores__cotainer">
                      <div className="row px-0">
                        <div className="col-md-12 d-flex">
                          {/* <CircularProgressbar
                            value={showData?.overallScore}
                            text={`${showData?.overallScore}%`}
                            className="screen-score__circle"
                            styles={buildStyles({
                              rotation: 0,
                              strokeLinecap: "butt",
                              textSize: "16px",
                              pathTransitionDuration: 0.5,
                              pathColor: `rgba(62, 152, 199, 1})`,
                              textColor: "#30adf3",
                              trailColor: "#d6d6d6",
                              backgroundColor: "#3e98c7",
                            })}
                          /> */}
                          <div className="px-2">
                            <h5>AI Match Score</h5>
                            <p>
                              {showData?.content?.overallInsight == null ||
                              showData?.content?.overallInsight === ""
                                ? "No data found"
                                : showData?.content?.overallInsight}
                            </p>
                          </div>
                        </div>
                        {/* <div className="col-md-6 ps-3">
                          <div className="w-100 d-flex mb-2">
                            <small className="w-25 me-2 text-nowrap">
                              Work History:{" "}
                              {showData?.content?.workHistoryScore >= 50 ? (
                                <FontAwesomeIcon
                                  className="fa-thumbs-up"
                                  icon={faThumbsUp}
                                />
                              ) : (
                                <FontAwesomeIcon icon={faThumbsDown} />
                              )}
                            </small>
                          </div>
                          <div className="w-100 d-flex mb-2">
                            <small className="w-25 me-2 text-nowrap">
                              Skills:{" "}
                              {showData?.content?.skillsScore >= 50 ? (
                                <FontAwesomeIcon
                                  className="fa-thumbs-up"
                                  icon={faThumbsUp}
                                />
                              ) : (
                                <FontAwesomeIcon icon={faThumbsDown} />
                              )}
                            </small>
                          </div>
                          <div className="w-100 d-flex">
                            <small className="w-25 me-2 text-nowrap">
                              Education:{" "}
                              {showData?.content?.educationScore >= 50 ? (
                                <FontAwesomeIcon
                                  className="fa-thumbs-up"
                                  icon={faThumbsUp}
                                />
                              ) : (
                                <FontAwesomeIcon icon={faThumbsDown} />
                              )}
                            </small>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal
                  aria-labelledby="modal-invite"
                  size="lg"
                  show={showInvite}
                  onHide={handleCloseInvite}
                  centered
                  backdrop="static"
                >
                  <Modal.Header
                    closeButton
                    className="bg-white pb-0 border-bottom-0"
                  ></Modal.Header>
                  <Modal.Body className="ai-scores__modal pt-0 bg-white pt-3">
                    <div className="profile-pic__container d-flex">
                      <div className="information__container">
                        <h3 className="text-capitalize">
                          {/* {showDataTalent?.firstName} {showDataTalent?.lastName} */}
                          {showDataTalent?.ifSubs === 1
                            ? `${showDataTalent.firstName} ${showDataTalent.lastName}`
                            : "Talent"}
                        </h3>
                      </div>
                      <div className="regenarate-button__container ms-auto d-block">
                        <button
                          className="btn btn-primary regenarate-button"
                          onClick={sentInvitationTalent}
                        >
                          Invite to apply
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className="row ">
                      <div className="col-md-6 text-capitalize">
                        <p className="fw-bolder">Skills</p>
                        <p>{showDataTalent?.content?.skillsInsight + " "}</p>
                      </div>
                      <div className="col-md-6">
                        <p className="fw-bold">Experience</p>
                        <p>
                          {showDataTalent?.content?.workExperienceInsight} years
                        </p>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>

                <CustomOffcanvas
                  show={isOCShowing}
                  handleClose={handleCloseProfile}
                  placement="end"
                  title="View Candidate"
                >
                  <AllInformation
                    clientData={clientData}
                    talentSubs={talentSubs}
                    item={showDataProfile}
                    hasNotes={true}
                    typeList="Candidate"
                  />
                </CustomOffcanvas>

                <Table className="table__recommendation">
                  <thead>
                    <tr>
                      <td>Score</td>
                      <td>Insight</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {itemData?.slice(0, 3).map((item, index) => (
                      <tr key={index}>
                        {loadingStates[index] ? (
                          <td colSpan={3}>
                            <Loader />
                          </td>
                        ) : insights[index] ? (
                          <>
                            <td>
                              <FontAwesomeIcon icon={faCircleUser} />{" "}
                              {insights[index]?.content?.score}
                            </td>
                            <td>
                              {insights[index]?.content?.overallInsight ===
                                null ||
                              insights[index]?.content?.overallInsight === ""
                                ? "No data found"
                                : insights[index]?.content?.overallInsight}
                            </td>
                            <td>
                              <div className="d-flex">
                                <Button
                                  className="mx-1 ai-scores position-relative"
                                  onClick={() =>
                                    handleShowInvite(insights[index])
                                  }
                                >
                                  <FontAwesomeIcon
                                    className=""
                                    icon={faUserPlus}
                                  />
                                </Button>
                                <Button
                                  className="mx-1 ai-scores position-relative"
                                  onClick={() =>
                                    handleViewProfile(insights[index])
                                  }
                                >
                                  <FontAwesomeIcon className="" icon={faFile} />
                                </Button>
                                <Button
                                  className="mx-1 ai-scores position-relative"
                                  onClick={() =>
                                    handleShowAiScore(insights[index])
                                  }
                                >
                                  <FontAwesomeIcon className="" icon={faEye} />
                                </Button>
                              </div>
                            </td>
                          </>
                        ) : (
                          <td colSpan={3}>
                            <Button
                              className="btn-success"
                              onClick={() => retryFetch(item, index)}
                            >
                              Retry
                            </Button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </>
        )}
      </WhiteContainer>
      {/* <WhiteContainer>
        <DragAndDrop columnsFromBackend={columnsFromBackendJO} />
      </WhiteContainer> */}
      <CustomModal
        show={modalShow}
        modalMessage={
          allError === "Error"
            ? "You have been automatically logged out"
            : "Something Went Wrong"
        }
        modalType={"Error"}
        onHide={() => setModalShow(false)}
        buttonName="Confirm"
        onClick={handleLogout}
      />
      <CustomModal
        show={showModal}
        modalType={
          modalType === "successNote" || modalType === "successInvite"
            ? "Success"
            : "Warning"
        }
        modalMessage={
          modalType === "successNote"
            ? "Invite sent"
            : modalType === "successInvite"
            ? "Invite successfully sent"
            : ""
        }
        buttonName="OK"
        onClick={() => setShowModal(false)}
      />
    </>
  );
};

export default SourcingSelection;
