import React, { useEffect, useState } from "react";
import WhiteContainer from "../../../../layouts/Container/WhiteContainer";
import Keyskills from "./KeySkills/Keyskills";
import BasicInformation from "./BasicInformation/BasicInformation";
import WorkHistory from "./WorkHistory/WorkHistory";
import CV from "./CV/CV";
import Certificate from "./Certificate/Certificate";
import "../../../../assets/scss/Page/Admin/ATS/_viewResume.scss";
import StatusHistory from "./StatusHistory/StatusHistory";
import RecentHistory from "./RecentHistory/RecentHistory";
import Profile from "./Profile/Profile";
import RemarksNote from "./RemarksNote/RemarksNote";
import talentData from "../../../../Data/ATS/talent.json";
import axios from "axios";
import Config from "../../../../Config/config";
import { localDevBase } from "../../../../services/API/localDevBase";
import { useAuthToken } from "../../../../Utilities/Auth/Token";
import { handleAxiosError } from "../../../../Utilities/Reusable/ErrorHandler/AxiosErrorHandler";
import { Button, Table } from "react-bootstrap";
import "react-circular-progressbar/dist/styles.css";
import ScreenScore from "./ScreenScore/ScreenScore";
import MatchScore from "./MatchScore/MatchScore";
import { useSelector } from "react-redux";

const AllInformation = ({
  sourceJobTalent,
  item,
  hasNotes,
  talentSubs,
  typeList,
  AllError,
  clientData,
  jobDetails,
  isJobRecommend,
}) => {
  console.log(item, sourceJobTalent, clientData, jobDetails);
  const token = useAuthToken();
  const devBase = localDevBase();

  const isAIMLOn = useSelector(
    (state) => state.reducer.feature.isfeatures.withAI
  );

  const [notesData, setNotesData] = useState([]);
  const [keySkill, setKeySkills] = useState([]);
  const [workHistory, setWorkHistory] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [statusHistory, setStatusHistory] = useState([]);
  const [client, setClient] = useState({});
  const [recentHistory, setRecentHistory] = useState([]);
  // const [isTalentData, setIssTalentData] = useState(talentData[0]);
  const data = {
    talentData,
  };

  useEffect(() => {
    getNote();
  }, []);

  useEffect(() => {
    let tempNotes = notesData.notes?.map((n) => {
      return {
        content: `${notesData.client.lastName}, ${notesData.client.firstName} created a note`,
        updatedAt: n.updatedAt,
        sortDate: new Date() - new Date(n.updatedAt),
      };
    });

    let tempStatusHistory = statusHistory?.map((s) => {
      return {
        content: `${client.lastName}, ${client.firstName} ${
          s.statusType === "submit" ? "submitted" : "moved"
        } candidate to ${s.status}`,
        updatedAt: s.updatedAt,
        sortDate: new Date() - new Date(s.updatedAt),
      };
    });

    let sortedRecentHistory = [];

    if (tempNotes) {
      sortedRecentHistory = [...tempNotes];
    }

    if (tempStatusHistory) {
      sortedRecentHistory = [...sortedRecentHistory, ...tempStatusHistory];
    }

    sortedRecentHistory = sortedRecentHistory.sort((a, b) => {
      return a.sortDate - b.sortDate;
    });

    setRecentHistory([...sortedRecentHistory]);
  }, [notesData, statusHistory]);

  const getNote = async () => {
    try {
      const responseNote = await axios.get(
        `${devBase}/api/ats/candidate-list/notes`,
        {
          params: { talentId: item.talentId, jobId: item.jobId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setNotesData(responseNote.data.result);
    } catch (error) {
      handleAxiosError(error);
    }

    try {
      const response = await axios.get(
        `${devBase}/api/ats/candidate-list/${item.talentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            jobId: item.jobId,
          },
        }
      );
      setKeySkills(response.data.result.skillSet);
      setWorkHistory(response.data.result.workHistory);
      setCertificate(response.data.result.certificate);
      // setNotesData(response.data.result);
      setClient(response.data.result.client);
      setStatusHistory(response.data.result.statusHistory[0]?.previousStatus);
    } catch (error) {
      console.log(error);
      handleAxiosError(error);
    }
  };

  const loadRemarksData = async () => {
    try {
      const responseNote = await axios.get(
        `${devBase}/api/ats/candidate-list/notes`,
        {
          params: { talentId: item.talentId, jobId: item.jobId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setNotesData(responseNote.data.result);
    } catch (error) {
      console.log(error);
      handleAxiosError(error);
    }
  };

  return (
    <>
      <WhiteContainer>
        <Profile
          clientData={clientData}
          talentSubs={talentSubs}
          data={item}
          hasNotes={hasNotes}
          loadRemarksData={loadRemarksData}
        />
      </WhiteContainer>

      {/* <WhiteContainer>
        <div className="view_resume_ats_section screen-score">
          <MatchScore data={item} jobDetails={jobDetails} />
        </div>
      </WhiteContainer> */}

      {isJobRecommend && (
        <WhiteContainer>
          <div className="view_resume_ats_section screen-score">
            <ScreenScore data={item} jobDetails={jobDetails} />
          </div>
        </WhiteContainer>
      )}

      {/* {sourceJobTalent === true ? (
        ""
      ) : (
        <>
          <WhiteContainer>
            <div className="view_resume_ats_section screen-score">
              <MatchScore data={item} />
            </div>
          </WhiteContainer>

          <WhiteContainer>
            <div className="view_resume_ats_section screen-score">
              <ScreenScore data={item} jobDetails={clientData} />
            </div>
          </WhiteContainer>
        </>
      )} */}
      {/* {isAIMLOn && (
        <>
          {sourceJobTalent === true ? (
            ""
          ) : (
            <>
              <WhiteContainer>
                <div className="view_resume_ats_section screen-score">
                  <MatchScore data={item} />
                </div>
              </WhiteContainer>
            </>
          )}
        </>
      )} */}
      {/* <WhiteContainer>
        <div className="view_resume_ats_section match-score">
          <ScreenScore />
        </div>
      </WhiteContainer> */}
      <div className="view_resume_ats">
        <div className="view_resume_ats_section">
          <WhiteContainer>
            <BasicInformation talentSubs={talentSubs} data={item} />
          </WhiteContainer>
          {talentSubs === true ? (
            ""
          ) : (
            <>
              <WhiteContainer>
                <Certificate data={certificate} />
              </WhiteContainer>
              <WhiteContainer>
                <RecentHistory data={recentHistory} client={client} />
              </WhiteContainer>
            </>
          )}
          <WhiteContainer>
            <StatusHistory data={statusHistory} client={client} />
          </WhiteContainer>
        </div>
        <div className="view_resume_ats_section">
          <WhiteContainer>
            <Keyskills data={keySkill} />
          </WhiteContainer>
          <WhiteContainer>
            <WorkHistory data={workHistory} />
          </WhiteContainer>
          {talentSubs === true ? (
            ""
          ) : (
            <>
              <WhiteContainer>
                <RemarksNote data={notesData} />
              </WhiteContainer>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AllInformation;
