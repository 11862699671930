import React, { useEffect, useState } from "react";
import { Accordion, Button, Card, Table } from "react-bootstrap";
import Config from "../../../../../Config/config";
import axios from "axios";
import { useAuthToken } from "../../../../../Utilities/Auth/Token";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

const ScreenScore = ({ data, jobDetails }) => {
  const [questionData, setQuestionData] = useState();
  const [screenScore, setScreenScore] = useState();
  const [answerAndQuestion, setAnswerAndQuestion] = useState();
  const [error, setError] = useState();
  const clientId = data.clientId;
  const jobId = data.jobId;
  const talentId = data.talentId;
  // const [content, setContent] = useState([]);
  const token = useAuthToken();
  useEffect(() => {
    const loadScreeningScore = async () => {
      try {
        const response = await axios.get(`${Config.ats}/api/ats/ai/qna`, {
          params: { talentId: data.talentId, jobId: jobDetails.jobId },
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        console.log("im the response GET", response);
        setQuestionData(response);
      } catch (error) {
        console.log(error);
        setError(error);
      }
    };

    loadScreeningScore();
  }, [jobId]);

  useEffect(() => {
    const content = questionData?.data?.result.map((item, key) => ({
      questionId: item.question.questionId,
      question: item.question.question,
      answerId: item.answer.answerId,
      answer: item.answer.answer,
    }));
    console.log(content);

    if (content) {
      loadQuestionScore(content);
    }
  }, [questionData, jobId]);

  const loadQuestionScore = async (content) => {
    const payload = {
      createdAt: "2024-05-30T15:17:39.306Z",
      clientId: clientId,
      jobId: jobId,
      applicantId: talentId,
      platform: "NXT",
      content,
    };
    try {
      const response = await axios.post(
        `${Config.ai}/api/screening/Question`,
        payload
      );
      console.log("im the response POST", response);
      setScreenScore(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (screenScore) {
      const saveInsight = async () => {
        const payload = {
          score: screenScore?.data?.overallScore,
          insight: screenScore?.data?.overallInsight,
          talentId: talentId,
          jobId: jobId,
        };
        console.log("payload ko to", payload);
        try {
          const response = await axios.post(
            `${Config.ats}/api/ats/ai/score`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } catch (error) {
          console.log(error);
        }
      };
      saveInsight();
    }
  }, [screenScore]);

  useEffect(() => {
    const getSaveInsight = async () => {
      try {
        const response = await axios.get(`${Config.ats}/api/ats/ai/score`, {
          params: { talentId: talentId, jobId: jobId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("if have response", response);
      } catch (error) {
        console.log(error);
      }
    };
    getSaveInsight();
  }, []);

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log("totally custom!")
    );

    return (
      <button
        className="btn p-0"
        type="button"
        onClick={decoratedOnClick}
        disabled={screenScore?.data?.content === 0 ? true : false}
      >
        {children}
      </button>
    );
  };

  return (
    <>
      {error?.response?.status === 400 ? (
        "This applicant was unable to answer the screening questionaires."
      ) : (
        <div className="d-flex">
          <div className="me-auto">
            <h4>
              Screening Analysis:
              <span className="screen-score__green">
                &nbsp;{screenScore?.data?.overallScore}
              </span>
            </h4>
            {/* <small>*Based on AI Screening</small> */}
            <p>{screenScore?.data?.overallInsight}</p>
            <Accordion flush>
              <Card className="shadow-none">
                <Card.Header className="p-0">
                  <CustomToggle eventKey="0">
                    <h4>
                      {screenScore?.data?.content === 0
                        ? "Screening questionnaires for this job post have not yet been set."
                        : "View details"}
                    </h4>
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="p-0">
                    <h3 className="p-1 fw-bold">Question</h3>
                    {screenScore?.data?.content.map((item, key) => {
                      return (
                        <div key={key}>
                          <p className="fw-bold">{item.question}</p>
                          <p className="border-bottom">
                            <span className="fw-bold">Answer:</span>{" "}
                            {item.answer}
                          </p>
                        </div>
                      );
                    })}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* <Accordion.Item eventKey="0">
            <Accordion.Header > 
              {screenScore?.data?.content === 0
                ? "Screening questionnaires for this job post have not yet been set."
                : "View details"}
            </Accordion.Header>
            <Accordion.Body>
              <h3 className="p-1 fw-bold">Question</h3>
              {screenScore?.data?.content.map((item, key) => {
                return (
                  <div key={key}>
                    <p className="fw-bold">{item.question}</p>
                    <p className="border-bottom">
                      <span className="fw-bold">Answer:</span> {item.answer}
                    </p>
                  </div>
                );
              })}
            </Accordion.Body>
          </Accordion.Item> */}
            </Accordion>
          </div>
          {/* <div className="ms-auto">
        <Button className="match-score__btn">Regenerate</Button>
      </div> */}
        </div>
      )}

      {/* <div className="view-resume__table-height d-flex">
        <Table bordered hover size="sm">
          <thead>
            <tr>
              <th>Question</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            {questionData?.data?.result?.map((item, key) => {
              return (
                <>
                  <tr key={key}>
                    <td>{item.question?.question}</td>
                    <td>{item.answer.answer}</td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>

        <Table bordered hover size="sm" className="w-25">
          <thead>
            <tr>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {screenScore?.data?.content?.map((item, key) => {
              return (
                <tr key={key}>
                  <td>{item?.score}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div> */}
    </>
  );
};

export default ScreenScore;
