import React, { useEffect, useState } from "react";
import { Alert, Button } from "reactstrap";
import "../../../assets/scss/Talent/Layout/MyResume/KeySkills.scss";
import KeySkillModal from "../../FormTalent/MyResume/KeySkillModal";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMultiply } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../layouts/loader/Loader";
import { List } from "react-content-loader";
import { useAuthToken } from "../../../Utilities/Auth/Token";
import Config from "../../../Config/config";
import { useSelector } from "react-redux";
const KeySkills = ({ hasAI }) => {
  console.log(hasAI);
  const [btnIfAi, setBtnIfAi] = useState(
    hasAI?.skillSet.length > 0 ? true : false
  );
  const skillSetFromAI = hasAI?.skillSet || [];
  console.log(skillSetFromAI);
  const skillSetFromProfile = useSelector(
    (state) => state.reducer.profile?.currentProfile?.skillSet || []
  );
  console.log(skillSetFromProfile);

  const [talentInfo, setTalentInfo] = useState({
    skillSet: [...skillSetFromProfile, ...skillSetFromAI],
  });

  console.log(talentInfo);

  // useEffect(() => {
  //   setTalentInfo({
  //     ...useSelector((state) => state.reducer.profile?.currentProfile),
  //     skillSet: [...skillSetFromProfile, ...skillSetFromAI],
  //   });
  // }, [hasAI, skillSetFromProfile]);

  console.log(talentInfo);
  const token = useAuthToken();
  const apiKey = process.env.REACT_APP_TALENT_API;
  const [beginner, setBeginner] = useState([]);
  const [intermediate, setIntermediate] = useState([]);
  const [advance, setAdvance] = useState([]);

  const [showKeySkillModal, setShowKeySkillModal] = useState(false);

  const [loading, setloading] = useState(false);

  // const getSkillData = async () => {
  //   setloading(true);
  //   try {
  //     const response = await axios.get(
  //       `${Config.nxtApi}/api/talent/myprofile`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     console.log(response);
  //     if (response.data?.code === "0") {
  //       setBeginner(response.data.data[0].skillSet);
  //       setIntermediate(response.data.data[0].skillSet);
  //       setAdvance(response.data.data[0].skillSet);
  //       setloading(false);
  //     }
  //   } catch (error) {
  //     setloading(false);
  //     console.log(error);
  //   }
  // };

  const clickKeySkillsBtn = () => {
    setShowKeySkillModal(true);
  };
  // uncomment ai
  // useEffect(() => {
  //   if (skillSetFromProfile.length > 0 || skillSetFromAI.length > 0) {
  //     setTalentInfo({
  //       skillSet: [...skillSetFromProfile, ...skillSetFromAI],
  //     });
  //   }
  // }, [skillSetFromAI]);

  useEffect(() => {
    console.log("++++++++++++++++++++++", talentInfo.skillSet);
    if (talentInfo.skillSet) {
      setBeginner(
        talentInfo.skillSet.filter(
          (skill) => skill.level === "Beginner" || skill.level === ""
        )
      );
      setIntermediate(
        talentInfo.skillSet.filter((skill) => skill.level === "Intermediate")
      );
      setAdvance(
        talentInfo.skillSet.filter((skill) => skill.level === "Advance")
      );
    }
  }, [talentInfo, skillSetFromAI, skillSetFromProfile]);

  const deleteAIData = () => {
    setBtnIfAi(false);
    setTalentInfo((prevTalentInfo) => ({
      skillSet: skillSetFromProfile,
    }));
  };

  return (
    <div className="key-skills-talent">
      <div className="header-container">
        <h6>KEY SKILLS</h6>
        <Button onClick={() => clickKeySkillsBtn()}>
          {btnIfAi ? "Save Skill Updates" : "Add / Edit Key Skills"}
        </Button>
      </div>
      <hr className="line-seperator" />
      <div className="body-container">
        {loading ? (
          <>
            <List />
          </>
        ) : (
          <>
            <h5>Beginner</h5>
            <div className="level-container">
              {beginner.length > 0 ? (
                <>
                  {beginner.map((info, key) => (
                    <div
                      className="level-content level-content__beginner"
                      key={key}
                    >
                      <p>
                        {info.skill} - {info.experience}
                      </p>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <Alert color="secondary" style={{ width: "100%" }}>
                    No beginner skill
                  </Alert>
                </>
              )}
            </div>
            <h5>Intermediate</h5>
            <div className="level-container">
              {intermediate.length > 0 ? (
                <>
                  {intermediate?.map((info, key) => (
                    <div
                      className="level-content level-content__intermediate"
                      key={key}
                    >
                      <p>
                        {info.skill} - {info.experience}
                      </p>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <Alert color="secondary" style={{ width: "100%" }}>
                    No intermediate skill
                  </Alert>
                </>
              )}
            </div>
            <h5>Advance</h5>
            <div className="level-container">
              {advance.length > 0 ? (
                <>
                  {advance?.map((info, key) => (
                    <div
                      className="level-content level-content__advance"
                      key={key}
                    >
                      <p>
                        {info.skill} - {info.experience}
                      </p>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <Alert color="secondary" style={{ width: "100%" }}>
                    No advance skill
                  </Alert>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <KeySkillModal
        isOpen={showKeySkillModal}
        close={() => setShowKeySkillModal(false)}
        keySkillList={talentInfo.skillSet}
        save={deleteAIData}
      />
    </div>
  );
};

export default KeySkills;
