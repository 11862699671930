import React, { useState, useRef, useEffect } from "react";
import { Button, Input, Label } from "reactstrap";
import "../../assets/scss/ManageJob.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookmark,
  faLocationPin,
  faPenSquare,
  faPlus,
  faSort,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { List } from "react-content-loader";
import FormManageJobModal from "./FormManageJobModal";
import WarningModal from "../WarningModal";
import useDebounce from "../useDebounce";
import { Table } from "react-bootstrap";
import ShareBtn from "../UI/SharePop/ShareBtn";
import he from "he";
import { EmailIcon, EmailShareButton } from "react-share";
import Invitation from "../UI/SharePop/Invitation";
import Config from "../../Config/config";
import { useAuthToken } from "../../Utilities/Auth/Token";
import { RequestMethod } from "../../Utilities/Reusable/Method/RequestMethod";
import moment from "moment";
import QuestionModal from "../QuestionModal";
const FormManageJob = ({ id, isOpen, toggle }) => {
  const token = useAuthToken();
  const [hasCancelBtn, setHasCancelBtn] = useState(false);
  const apiKey = process.env.REACT_APP_CLIENT_API;
  const navigate = useNavigate();
  const [jobsData, setJobsData] = useState([]);
  const [totalJobs, setTotalJobs] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage, setJobsPerPage] = useState(10);
  // const jobsPerPage = 10;
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const [showManageJobModal, setShowManageJobModal] = useState(false);
  const lastKeyRef = useRef(null);

  const [selectedJobId, setSelectedJobId] = useState("");
  const [selectedCreatedat, setSelectedCreatedat] = useState("");
  const [selectedJobPostStatus, setSelectedJobPostStatus] = useState("");

  const [modalOpen, setModalOpen] = useState(false);

  const debouncedSearchQuery = useDebounce(searchQuery, 500); // Adjust the delay as needed

  const [isLastData, setIsLastData] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  //
  const [job_id, setJobId] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const navigateApplicant = (id, jobTitle, companyName, jobOverview) => {
    const obj = {
      jobId: id,
      jobTitle: jobTitle,
      companyName: companyName,
      jobOverview: jobOverview,
    };

    localStorage.setItem("jobList", JSON.stringify(obj));
    navigate("/client/applicant-list");
  };

  useEffect(() => {
    fetchData();
  }, [jobsPerPage, debouncedSearchQuery]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsLastData(false); // Reset isLastData state
    setIsButtonDisabled(false); // Reset isButtonDisabled state
    try {
      const payload = {
        numRow: jobsPerPage,
        sortKeys: "desc",
        filterKeys: [
          {
            attribute: "allFields",
            value: debouncedSearchQuery,
          },
        ],
      };

      const response = await RequestMethod(
        "post",
        "nxtApi",
        "/api/client/jobs",
        payload,
        token
      );

      const fetchedJobsData = response.data.data;

      const newLastKey = response.data.lastKey;
      lastKeyRef.current = newLastKey;

      const totalJobsCount = response.data.total;
      setJobId("");
      setJobsData(fetchedJobsData);
      setTotalJobs(totalJobsCount);
      setIsLastData(newLastKey === null); // Check if it's the last data
      setIsButtonDisabled(newLastKey === null); // Disable the button if it's the last data
    } catch (error) {
      console.error("Error fetching jobs data:", error);
    }
    setIsLoading(false);
  };

  const handlePageClick = async () => {
    setIsLoading(true);
    // setJobsPerPage(jobsPerPage);
    try {
      const payload = {
        numRow: jobsPerPage,
        sortKeys: "desc",
        lastKey: lastKeyRef.current, // Use the lastKey from the ref
        filterKeys: [
          {
            attribute: "allFields",
            value: searchQuery,
          },
        ],
      };

      const response = await RequestMethod(
        "post",
        "nxtApi",
        "/api/client/jobs",
        payload,
        token
      );
      //
      const fetchedJobsData = response.data.data;
      const newLastKey = response.data.lastKey;
      lastKeyRef.current = newLastKey;

      setJobsData((prevJobsData) => [...prevJobsData, ...fetchedJobsData]);
      setIsLastData(newLastKey === null); // Check if it's the last data
      setIsButtonDisabled(newLastKey === null); // Disable the button if it's the last data
    } catch (error) {
      console.error("Error fetching jobs data:", error);
    }
    setIsLoading(false);
  };

  const handleDeleteJobClick = async (jobId) => {
    try {
      const url = `${Config.nxtApi}/api/client/deletejob`;

      await axios.post(
        url,
        {
          createdAt: jobsData.find((job) => job.id === jobId).createdAt,
          id: jobsData.find((job) => job.id === jobId).id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Remove the deleted job from the state
      setJobsData((prevJobsData) =>
        prevJobsData.filter((job) => job.id !== jobId)
      );

      setModalOpen(false); // Close the modal after successful deletion

      // Refresh the page
      window.location.reload();
    } catch (error) {
      console.error("Error deleting job:", error);
    }
  };

  const onConfirm = (jobId) => {
    handleDeleteJobClick(jobId);
  };

  const handleDeleteConfirmation = (jobId) => {
    setSelectedJobId(jobId);
    toggleModal(); // Open the modal
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setCurrentPage(1); // Reset currentPage when the search query changes
  };

  const handleEditJobClick = (jobId, createdAt, jobPostStatus) => {
    setShowManageJobModal(true); // Open the modal
    setSelectedJobId(jobId); // Set the selected job ID
    setSelectedCreatedat(createdAt); // Set the selected job ID
    setSelectedJobPostStatus(jobPostStatus); // Set the selected jobPostStatus value
  };

  const handleAddpostjobClick = () => {
    navigate("/client/job-posting");
  };

  const previewJob = (id) => {
    const obj = {
      job_id: id,
    };

    localStorage.setItem("previewJob", JSON.stringify(obj));

    navigate(`/client/view-job-details/${id}`);
  };

  function isNxtClient() {
    return window.location.hostname === "www.nxt.com.ph";
  }

  function isDevClient() {
    return window.location.hostname === "dev.nxt.com.ph";
  }

  function isStagClient() {
    return window.location.hostname === "stage.nxt.com.ph";
  }

  const baseUrl = isNxtClient()
    ? "https://www.nxt.com.ph"
    : isDevClient()
    ? "https://dev.nxt.com.ph"
    : isStagClient()
    ? "https://stage.nxt.com.ph"
    : "http://localhost:3000";

  const today = moment().startOf("day");
  // const startDate = moment(job.postingDate.startDate).isSameOrAfter(today);
  // const isTodayOrFutureStartDate = startDate.isSameOrAfter(today);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const handleSort = (column) => {
    let direction = "asc";
    if (sortColumn === column && sortDirection === "asc") {
      direction = "desc";
    }
    setSortColumn(column);
    setSortDirection(direction);

    const sortedJobsData = [...jobsData].sort((a, b) => {
      const columnA = a[column].toUpperCase();
      const columnB = b[column].toUpperCase();

      if (columnA < columnB) {
        return direction === "asc" ? -1 : 1;
      }
      if (columnA > columnB) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setJobsData(sortedJobsData);
  };
  const [modalOpen3, setModalOpen3] = useState(false);
  const toggleModal3 = () => {
    setModalOpen3(!modalOpen3);
  };
  return (
    <div>
      <div className="add-job-container">
        <div className="add-job-content">
          <h1>Add Job</h1>
          <div className="d-flex">
            <Button onClick={handleAddpostjobClick}>
              <FontAwesomeIcon icon={faPlus} className="faPlusIcon" />
              Add Job
            </Button>
            {/* <Button
              className="ms-auto"
              onClick={() => navigate("/admin/ats/manage-job")}
            >
              Manage ATS
            </Button> */}
          </div>
        </div>
      </div>
      <div className="update-profile-container">
        <div className="company-profile">
          <h1>Manage Jobs</h1>
          <hr className="line" />
        </div>
        <div className="entries-search-container">
          <div className="entries-container">
            <Label>Search</Label>
            <Input type="select">
              <option>10</option>
            </Input>
            <Label>entries</Label>
          </div>
          <div className="search-container">
            <Label>Search</Label>
            <Input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="joblist-table-container">
          <Table className="joblist-table" responsive>
            <thead>
              <tr>
                <th>
                  <FontAwesomeIcon
                    icon={faSort}
                    className="sort-icon"
                    onClick={() => handleSort("job_id")}
                  />
                  <Label>Job ID</Label>
                </th>
                <th>
                  <FontAwesomeIcon
                    icon={faSort}
                    className="sort-icon"
                    onClick={() => handleSort("jobTitle")}
                  />
                  <Label>Job Title</Label>
                </th>
                <th>
                  {/* <FontAwesomeIcon icon={faSort} className="sort-icon" /> */}
                  <Label>Application</Label>
                </th>
                <th>
                  {/* <FontAwesomeIcon icon={faSort} className="sort-icon" /> */}
                  <Label>Status</Label>
                </th>
                <th>
                  <Label>Action</Label>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="5" className="loader">
                    <List />
                  </td>
                </tr>
              ) : jobsData.length === 0 ? (
                <tr>
                  <td colSpan="5" className="no-data">
                    No data available.
                  </td>
                </tr>
              ) : (
                jobsData.map((job, key) => {
                  const shareUrl = `${baseUrl}/share/jobs/jd/${job.job_id}`;
                  const decodedJobDescription = he.decode(
                    job.jobDescription.replace(/\s+/g, " ").trim()
                  );

                  // console.log(
                  //   `${
                  //     Config.nxtApi
                  //   }/api/jobs/job/company/logo?clientId=${encodeURIComponent(
                  //     job.clientId
                  //   )}`
                  // );
                  const textWithoutTags = decodedJobDescription
                    .toString()
                    .replace(/<[^>]*>/g, "");
                  const message = `<!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    
                    </head>
                    <body>
                        <img crossorigin="anonymous" src="https://dev.nxt.com.ph/images/emailbanner.png"
                        width="500" />
                        </br>
                        <h2>You're invited!</h2>    
                        <p><strong>${job.companyName}</strong> is inviting you to apply for <strong>${job.jobTitle}</strong> position. \nTo learn more about the job, please click on the link below. Good luck!</p>  
                        <a href="${shareUrl}">${shareUrl}</a>
                    </body>
                    </html>
                    `;
                  const messageId = job.id;
                  // console.log(message);
                  // console.log(messageId);
                  return (
                    <>
                      <tr key={job.id}>
                        <td>
                          <div
                            className="jobID"
                            style={{ textTransform: "uppercase" }}
                          >
                            {job.job_id}
                          </div>
                        </td>
                        <td>
                          <div className="jobtitle-container">
                            <Label
                              className="job-title-text"
                              onClick={() => {
                                previewJob(job.job_id);
                              }}
                            >
                              {job.jobTitle}{" "}
                            </Label>
                            <div className="location-container">
                              <div>
                                <FontAwesomeIcon icon={faLocationPin} />
                                <Label>{job.city}</Label>
                              </div>
                              <div>
                                <FontAwesomeIcon icon={faBookmark} />
                                <Label>{job.workArrangement} </Label>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <Label
                            className="view-applicants-label"
                            onClick={() =>
                              navigateApplicant(
                                job.id,
                                job.jobTitle,
                                job.companyName,
                                job.jobOverview
                              )
                            }
                          >
                            View Applicants
                          </Label>
                        </td>
                        <td>
                          {job.jobPostStatus === "open" ? (
                            <div className="open-status">Open</div>
                          ) : job.jobPostStatus === "closed" ? (
                            <div className="close-status">Closed</div>
                          ) : job.jobPostStatus === "draft" ? (
                            <div className="draft-status">Draft</div>
                          ) : job.jobPostStatus === "expired" ? (
                            <div className="inactive-status">Expired</div>
                          ) : null}
                        </td>
                        <td>
                          <div className="action-container">
                            {job.jobPostStatus === "open" && (
                              <div className="email-container">
                                <Invitation
                                  disabled={
                                    !moment(job.postingDate.startDate).isSame(
                                      moment(),
                                      "day"
                                    )
                                  }
                                  url={`${baseUrl}/share/jobs/jd/${job.job_id}`}
                                  isList={true}
                                  subject={job.jobTitle}
                                  messageId={messageId}
                                  message={message}
                                />
                              </div>
                            )}
                            <div
                              className="edit-container"
                              onClick={() =>
                                handleEditJobClick(
                                  job.id,
                                  job.createdAt,
                                  job.jobPostStatus
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faPenSquare} />
                            </div>
                            {job.jobPostStatus === "closed" ? (
                              <></>
                            ) : (
                              <div
                                className="delete-container"
                                onClick={() => handleDeleteConfirmation(job.id)}
                              >
                                <FontAwesomeIcon icon={faTrashCan} />
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
        <div className="entries-pagiation-container">
          <div>
            <Button onClick={handlePageClick} disabled={isButtonDisabled}>
              {isLastData ? "No More to Load" : "Load More"}
            </Button>
          </div>
          <FormManageJobModal
            isOpen={showManageJobModal}
            close={() => setShowManageJobModal(false)}
            className="edit-save-draft-modal"
            jobId={selectedJobId} // Pass the selected job ID to the modal
            createdAt={selectedCreatedat} // Pass the selected createdAt value to the modal
            jobPostStatus={selectedJobPostStatus} // Pass the selected createdAt value to the modal
            size="lg"
          />
          <WarningModal
            toggleModal={toggleModal}
            modalOpen={modalOpen}
            modalLabel="Oops"
            modalSublabel="Are you sure you want to close this job?"
            confirmButtonText="Confirm"
            onConfirm={() => onConfirm(selectedJobId)}
            onCancel={toggleModal}
          />
        </div>
      </div>
    </div>
  );
};

export default FormManageJob;
