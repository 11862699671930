import React, { useEffect, useState } from "react";
import Footer from "../../layouts/Footer";
import "../../assets/scss/Jobposting.scss";
import { Container } from "reactstrap";
import SidebarTalent from "../../components/LayoutTalent/SidebarTalent";
import { TabTitle } from "../../Utilities/FunctionTitle";
import HeaderTalent from "../../components/LayoutTalent/HeaderTalent";
import BannerJobListDetails from "../../components/LayoutTalent/BannerJobListDetails";
import SidebarJobList from "../../components/LayoutTalent/JobList/SidebarJobList";
import JobListSidebar from "../../components/LayoutTalent/JobList/JobListDetails/JobListSidebar";
import JobListContent from "../../components/LayoutTalent/JobList/JobListDetails/JobListContent";
import TalentAnalytics from "../../Utilities/Analytics/TalentAnalytics";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useTalent } from "../../hook/useTalent";
import { List } from "react-content-loader";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import CustomInput from "../../components/Form/CustomInput";
import axios from "axios";
import Config from "../../Config/config";
import { useAuthToken } from "../../Utilities/Auth/Token";
import CustomModal from "../../components/UI/Modal";
import { Message } from "../../Utilities/Reusable/Message/Message";

const JobListDetailsAiQuestionarePagTalent = () => {
  const token = useAuthToken();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const methods = useForm();
  const [questionId, setQuestionId] = useState("");
  const [answer, setAnswer] = useState("");
  const [modalInfo, setModalInfo] = useState({
    show: false,
  });

  const listQuestions = location.state?.listQuestion;
  console.log("list of question", listQuestions);
  //   const [jobId, setJobId] = useState("");
  const [showQuestion, setShowQuestion] = useState(listQuestions);
  console.log(showQuestion);
  const getJobPayload = JSON.parse(localStorage.getItem("jobPayload"));
  const { viewJobDetails, viewJobData, viewJobDetailsLoad } = useTalent();
  const [jobDetails, setJobDetails] = useState([]);
  console.log(jobDetails, viewJobData);
  useEffect(() => {
    viewJobDetails(getJobPayload.id, getJobPayload.job_id);
  }, [id]);

  useEffect(() => {
    if (viewJobData) {
      setJobDetails(viewJobData);
    }
  }, [viewJobData]);

  TabTitle("NXT | Job List Details");

  const jobId = location.state?.jobId;

  // useEffect(() => {
  //   getData();
  // }, []);

  // const getData = async () => {
  //   try {
  //     const response = await axios.get(`${Config.ats}/api/ats/ai/screening`, {
  //       params: {
  //         jobId: jobId,
  //       },
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     console.log("show question", showQuestion?);
  //     setShowQuestion(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  console.log("show question", showQuestion);

  const postData = async (data) => {
    const questionIdList = Object.values(showQuestion).map(
      (item) => item.questionId
    );
    const setListAnswer = Object.values(data).map((item) => item);
    const listOfQuestionAndAnswer = questionIdList.map((item, index) => ({
      questionId: item,
      answer: setListAnswer[index],
    }));
    console.log(questionIdList);
    const payload = {
      answers: listOfQuestionAndAnswer,
      jobId,
    };
    console.log(payload);
    try {
      const response = await axios.post(
        `${Config.ats}/api/ats/ai/talent-screening`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("this is my response", response);
      setModalInfo({
        show: true,
        type: "Success",
        message: Message.talent.aiQuestionare[200],
        func: "successAnswerPost",
        bName: "Back to job list",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnClick = () => {
    if (modalInfo.func === "successAnswerPost") {
      navigate("/talent/job-list");
    }
  };

  return (
    <div className="background-imagePost">
      <TalentAnalytics />{" "}
      <div className="header-fixed">
        <HeaderTalent />
      </div>
      {jobDetails.length === 0 ? (
        <List />
      ) : (
        <>
          <div className="jobposting-banner">
            <BannerJobListDetails jobDetails={jobDetails} />{" "}
          </div>
          <Container className="jobposting-body">
            <div>
              <div className="sidebar-container">
                <JobListSidebar jobDetails={jobDetails} />
              </div>
            </div>

            <div className="company-profile-container">
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <div style={{ marginTop: "10px" }}>
                  <h2>Employers Questions</h2>
                </div>
              </div>
              <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(postData)}>
                  {showQuestion?.map((item, key) => {
                    console.log(item.question);
                    return (
                      <>
                        <CustomInput
                          {...methods.register(`question${key}`)}
                          name={`question${key}`}
                          label={`${item.question}?`}
                          type="text"
                          className="company-input-lg-width company-input-lg-width__mod"
                          classNameLabel="company-input-title text-black"
                        />
                      </>
                    );
                  })}
                  <div className="d-flex">
                    <div className="ms-auto">
                      <Button
                        type="submit"
                        onClick={() =>
                          methods.handleSubmit((data) => postData(data))()
                        }
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              </FormProvider>
            </div>
          </Container>
        </>
      )}
      <Footer />
      <CustomModal
        show={modalInfo.show}
        modalType={modalInfo.type}
        modalMessage={modalInfo.message}
        buttonName={modalInfo.bName}
        onClick={() => handleOnClick()}
      />
    </div>
  );
};

export default JobListDetailsAiQuestionarePagTalent;
